import Vue from 'vue';
import { defineComponent, reactive, watch } from '@vue/composition-api';
import { ZidInput, ZidCheckbox, ZidIcon, ZidButton, ZidTextarea, ZidLoader, ZidFileSelector } from '@zidsa/ui';
import Multiselect from 'vue-multiselect';
import BaseIcon from '@/components/base-icon/BaseIcon.vue';
import SearchIcon from '@/assets/icons/applications/SearchIcon.vue';
import BaseText from '@/components/text/BaseText.vue';
import router from '@/router/index';
import { RoutingRouteEnum } from '@/router/routes.enum';
import BreadCrumb from '@/domains/shipping/components/breadcrumb/BreadCrumb.vue';
import TabMultiLanguage from '@/components/tab-multi-language/TabMultiLanguage.vue';
import useAboutCompany from '@/domains/shipping/helper/hooks/about-company/useAboutCompany';
import { createNamespacedHelpers } from 'vuex-composition-helpers';
import { setApplicationDetailsToApi } from '@/domains/shipping/api/steps/application-details/applicationDetails';
import ErrorModel from '@/api/models/ErrorModel';

const { useGetters, useActions } = createNamespacedHelpers('shipping'); // module name

export default defineComponent({
  components: {
    ZidInput,
    BaseText,
    ZidCheckbox,
    ZidIcon,
    BaseIcon,
    SearchIcon,
    Multiselect,
    ZidButton,
    ZidTextarea,
    BreadCrumb,
    ZidLoader,
    ZidFileSelector,
    TabMultiLanguage,
  },
  setup() {
    const {
      v$,
      shippingAppId,
      createShippingAppFormState,
      partnerCountriesForCoverage,
      partnerCountriesForDeliveryCoverage,
      partnerCoveragePickupCities,
      originalArabicScreenshots,
      originalEnglishScreenshots,
      areArabicFiles,
      isArScreenshotsInvalid,
      isEnScreenshotsInvalid,
      initialArabicScreenshots,
      initialEnglishScreenshots,
      isInitialArabicScreenshotsLoading,
      isInitialEnglishScreenshotsLoading,
      isSaveDisabled,
      SHORT_DESCRIPTION_MAX_LENGTH,
      partnerCoverageDeliveryCities,
      showMessageForDeveloperNameArEnField,
      showMessageForPaymentDueDateArEnField,
      showMessageForShortDescriptionArEnField,
      showMessageForReceivingOrdersTimesArEnField,
      showMessageForOrderDeliveryTimesArEnField,
      showMessageForShippingWeightArEnField,
      AboutCompanyLocationTypesEnum,
      AboutCompanyInputPropertiesEnum,
      isLoadingPickupCountries,
      isLoadingDeliveryCountries,
      isLoadingPickupCities,
      isLoadingDeliveryCities,
      onInputDataChange,
      onArabicScreenshotsChange,
      onEnglishScreenshotsChange,
      onSelectedCountryRemove,
      onArabicScreenshotsRemove,
      onEnglishScreenshotsRemove,
      customNameForLocations,
    } = useAboutCompany();

    const disabledBreadCrumbList = reactive([
      RoutingRouteEnum.CreateShippingApplication_ShippingOptionDetails,
      RoutingRouteEnum.CreateShippingApplication_WebhookManagement,
      RoutingRouteEnum.CreateShippingApplication_Publish,
    ]);

    const { inProgressFullApp } = useGetters(['inProgressFullApp']);
    const { addStepCompleted, setInProgressFullApplication } = useActions([
      'addStepCompleted',
      'setInProgressFullApplication',
    ]);

    const onBackClicked = () => {
      router.push({ name: RoutingRouteEnum.CreateShippingApplication_GeneralSettings }).catch(() => {
        //
      });
    };

    const onSubmit = async () => {
      if (shippingAppId) {
        const shippingApplicationDetails = {
          payment_due_en: createShippingAppFormState.paymentDueDateEnglish,
          payment_due_ar: createShippingAppFormState.paymentDueDateArabic,
          locations_from: Array.isArray(createShippingAppFormState.pickup.cities)
            ? createShippingAppFormState.pickup.cities.map((c) => c.value)
            : [],
          locations_to: Array.isArray(createShippingAppFormState.delivery.cities)
            ? createShippingAppFormState.delivery.cities.map((c) => c.value)
            : [],
          times_to_pickup_en: createShippingAppFormState.orderDeliveryTimesEnglish,
          times_to_pickup_ar: createShippingAppFormState.orderDeliveryTimesArabic,
          times_to_deliver_en: createShippingAppFormState.receivingOrdersTimesEnglish,
          times_to_deliver_ar: createShippingAppFormState.receivingOrdersTimesArabic,
          developer_name_ar: createShippingAppFormState.developerNameArabic,
          developer_name_en: createShippingAppFormState.developerNameEnglish,
          short_description_ar: createShippingAppFormState.shortDescriptionArabic,
          short_description_en: createShippingAppFormState.shortDescriptionEnglish,
          weight_details_en: createShippingAppFormState.shippingWeightEnglish,
          weight_details_ar: createShippingAppFormState.shippingWeightArabic,
          screen_shots_en: [...originalEnglishScreenshots.list],
          screen_shots_ar: [...originalArabicScreenshots.list],
        };
        const response = await setApplicationDetailsToApi(shippingApplicationDetails, shippingAppId);
        if (response instanceof ErrorModel || !response.payload) {
          Vue.$toast.error('Failed to update application details');
          return;
        }
        const newFullApp = {
          payload: { ...response.payload },
        };
        setInProgressFullApplication(newFullApp);
        addStepCompleted(RoutingRouteEnum.CreateShippingApplication_ShippingOptionDetails);
        router
          .push({
            name: RoutingRouteEnum.CreateShippingApplication_ShippingOptionDetails,
          })
          .catch(() => {
            //
          });
        Vue.$toast.success('Application Details are Updated Successfully');
      } else {
        Vue.$toast.success('Application Not Found');
      }
    };

    watch(
      () => inProgressFullApp,
      (inPorgressFullAppValue) => {
        if (inPorgressFullAppValue.value) {
          const {
            payload: { app },
          } = inPorgressFullAppValue.value;
          createShippingAppFormState.pickup.countries = app.coverage.pickup_countries;
          createShippingAppFormState.pickup.cities = app.coverage.pickup_cities;
          createShippingAppFormState.delivery.countries = app.coverage.delivery_countries;
          createShippingAppFormState.delivery.cities = app.coverage.delivery_cities;
          createShippingAppFormState.receivingOrdersTimesArabic = app.times_to_pickup_ar;
          createShippingAppFormState.receivingOrdersTimesEnglish = app.times_to_pickup_en;
          createShippingAppFormState.orderDeliveryTimesArabic = app.times_to_deliver_ar;
          createShippingAppFormState.orderDeliveryTimesEnglish = app.times_to_deliver_en;
          createShippingAppFormState.shippingWeightArabic = app.weight_details_ar;
          createShippingAppFormState.shippingWeightEnglish = app.weight_details_en;
          createShippingAppFormState.appScreenshotsAr = app.screen_shots_ar;
          createShippingAppFormState.appScreenshotsEn = app.screen_shots_en;
        }
      },
      { deep: true },
    );

    return {
      v$,
      createShippingAppFormState,
      partnerCountriesForCoverage,
      partnerCountriesForDeliveryCoverage,
      partnerCoveragePickupCities,
      partnerCoverageDeliveryCities,
      originalArabicScreenshots,
      originalEnglishScreenshots,
      areArabicFiles,
      isArScreenshotsInvalid,
      isEnScreenshotsInvalid,
      initialArabicScreenshots,
      initialEnglishScreenshots,
      isInitialArabicScreenshotsLoading,
      isInitialEnglishScreenshotsLoading,
      isSaveDisabled,
      SHORT_DESCRIPTION_MAX_LENGTH,
      showMessageForDeveloperNameArEnField,
      showMessageForPaymentDueDateArEnField,
      showMessageForShortDescriptionArEnField,
      showMessageForReceivingOrdersTimesArEnField,
      showMessageForOrderDeliveryTimesArEnField,
      showMessageForShippingWeightArEnField,
      disabledBreadCrumbList,
      AboutCompanyLocationTypesEnum,
      AboutCompanyInputPropertiesEnum,
      isLoadingPickupCountries,
      isLoadingDeliveryCountries,
      isLoadingPickupCities,
      isLoadingDeliveryCities,
      onInputDataChange,
      onArabicScreenshotsChange,
      onEnglishScreenshotsChange,
      onSelectedCountryRemove,
      onArabicScreenshotsRemove,
      onEnglishScreenshotsRemove,
      customNameForLocations,
      onBackClicked,
      onSubmit,
    };
  },
});
