import httpClient from '@/api/httpClient';
import ErrorModel from '@/api/models/ErrorModel';
import { ShippingAppListingStatusProperty } from '@/api/models/ShippingApplicationModel';
import PublishAppResponseModel from '@/domains/shipping/models/PublishAppResponseModel';

const ENDPOINT_URL = '/market/app';

export const sendAppForReview = (applicationID: number): Promise<PublishAppResponseModel | ErrorModel> => {
  return httpClient
    .post(`${ENDPOINT_URL}/update/listing/${applicationID}`, {
      listing_status: ShippingAppListingStatusProperty.RequestToPublish,
    })
    .then((response) => new PublishAppResponseModel(response))
    .catch((error) => error);
};
