import httpClient from '@/api/httpClient';
import { CustomizationDetailsType } from '../../../types/create-app/createApp.enum';

const ENDPOINT_URL = '/partners/shipping/courier';

export const setCustomizationDetailsToApi = (app_id: number, customizationsDetails: CustomizationDetailsType) => {
  // update case
  if (customizationsDetails.id) {
    return httpClient
      .put(`${ENDPOINT_URL}/${app_id}/customizations/${customizationsDetails.id}`, customizationsDetails)
      .then((response) => response)
      .catch((error) => error);
  }
  // create case
  else {
    return httpClient
      .post(`${ENDPOINT_URL}/${app_id}/customizations`, customizationsDetails)
      .then((response) => response)
      .catch((error) => error);
  }
};
