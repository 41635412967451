import { defineComponent, reactive } from '@vue/composition-api';
import BaseText from '@/components/text/BaseText.vue';
import {
  ZidButton,
  ZidIcon,
  ZidInput,
  ZidTextarea,
  ZidModal,
  ZidLoader,
  ZidTable,
  ZidTableRowGroup,
  ZidTableHeader,
  ZidTableRow,
  ZidTableCell,
} from '@zidsa/ui';
import Multiselect from 'vue-multiselect';
import BaseIcon from '@/components/base-icon/BaseIcon.vue';
import DeleteIcon from '@/assets/icons/applications/DeleteIcon.vue';
import EditIcon from '@/assets/icons/applications/EditIcon.vue';
import router from '@/router/index';
import { RoutingRouteEnum } from '@/router/routes.enum';
import BreadCrumb from '@/domains/shipping/components/breadcrumb/BreadCrumb.vue';
import useWebhooksManagement from '@/domains/shipping/helper/hooks/webhooks-management/useWebhooksManagement';

export default defineComponent({
  components: {
    BreadCrumb,
    BaseText,
    ZidButton,
    ZidIcon,
    ZidInput,
    ZidTextarea,
    Multiselect,
    BaseIcon,
    DeleteIcon,
    EditIcon,
    ZidModal,
    ZidLoader,
    ZidTable,
    ZidTableRowGroup,
    ZidTableHeader,
    ZidTableRow,
    ZidTableCell,
  },
  setup() {
    const disabledBreadCrumbList = reactive([RoutingRouteEnum.CreateShippingApplication_Publish]);

    const onBackClicked = () => {
      router.push({ name: RoutingRouteEnum.CreateShippingApplication_ShippingOptionDetails }).catch(() => {
        //
      });
    };

    return {
      disabledBreadCrumbList,
      onBackClicked,
      ...useWebhooksManagement(),
    };
  },
});
