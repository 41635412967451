import BaseModel from '@/api/models/BaseModel';
import CustomizationModel from './CustomizationModel';

class PartnerCustomizationResponseModel extends BaseModel {
  public customization: CustomizationModel[];

  constructor(arg: any) {
    super(arg);
    this.customization = arg.customization;
  }
}

export default PartnerCustomizationResponseModel;
