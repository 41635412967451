import Vue from 'vue';
import { ref, computed, watch, reactive, onBeforeMount, onMounted } from '@vue/composition-api';
import useVuelidate from '@vuelidate/core';
import { required, helpers, maxLength } from '@vuelidate/validators';
import { getFileFromURL } from '@/api/top-level-apis/helpers/fileFromURL';
import router from '@/router/index';
import { AboutCompanyDataInterface } from '@/store/modules/shipping/applicationState.type';
import {
  ShippingCountriesType,
  ShippingCitiesType,
  AboutCompanyLocationTypesEnum,
  AboutCompanyInputPropertiesEnum,
} from '@/domains/shipping/types/create-app/createApp.enum';
import {
  ARABIC_TEXT_WITH_SPECIAL_CHARS,
  ENGLISH_TEXT_WITH_SPECIAL_CHARS,
  ARABIC_TEXT_WITH_NEW_LINE_AND_SPECIAL_CHARS,
  ENGLISH_TEXT_WITH_NEW_LINE_AND_SPECIAL_CHARS,
} from '@/helpers/regex/text';
import ShippingApplicationModel from '@/api/models/ShippingApplicationModel';
import ErrorModel from '@/api/models/ErrorModel';
import { getPartnerCountriesForCoverage } from '../../../api/partnerCountriesForCoverage';
import { getPartnerAvailableCoverageCities } from '../../../api/partnerAvailableCoverageCities';
import PartnerAvailableCountriesModel from '../../../models/PartnerAvailableCountriesModel';
import PartnerAvailableCitiesModel from '../../../models/PartnerAvailableCitiesModel';
import { createNamespacedHelpers } from 'vuex-composition-helpers';

const { useGetters } = createNamespacedHelpers('shipping');

const useAboutCompany = () => {
  let shippingApp: ShippingApplicationModel | null = null;
  let shippingAppId: number | null = null;
  let globalScreenshotsAr: string[] = [];
  let globalScreenshotsEn: string[] = [];
  const MAX_SCREENSHOTS_NUMBER = 10;
  const MIN_SCREENSHOTS_NUMBER = 1;
  const isArScreenshotsInvalid = ref(false);
  const isEnScreenshotsInvalid = ref(false);
  const isLoadingPickupCountries = ref(true);
  const isLoadingPickupCities = ref(true);
  const isLoadingDeliveryCountries = ref(true);
  const isLoadingDeliveryCities = ref(true);
  const isInitialArabicScreenshotsLoading = ref(false);
  const isInitialEnglishScreenshotsLoading = ref(false);
  const SHORT_DESCRIPTION_MAX_LENGTH = 100;

  const preSelectedCoveragePickupCountries = reactive<ShippingCountriesType>({
    selectAll: 'All',
    options: [],
  });

  const preSelectedCoveragePickupCities = reactive<ShippingCitiesType>({
    selectAll: 'All',
    options: [],
  });

  const preSelectedCoverageDeliveryCountries = reactive<ShippingCountriesType>({
    selectAll: 'All',
    options: [],
  });

  const preSelectedCoverageDeliveryCities = reactive<ShippingCitiesType>({
    selectAll: 'All',
    options: [],
  });

  const { inProgressFullApp } = useGetters(['inProgressFullApp']);

  if (inProgressFullApp.value) {
    const {
      payload: { app },
    } = inProgressFullApp.value;
    shippingApp = app;
    shippingAppId = app.app_id;

    globalScreenshotsAr = shippingApp?.screen_shots_ar?.split(',') ?? [];
    globalScreenshotsEn = shippingApp?.screen_shots_en?.split(',') ?? [];

    Object.entries(app.coverage.pickup_countries).forEach((country: any) => {
      preSelectedCoveragePickupCountries.options.push({
        label: `${country[1].name.en} - ${country[1].name.ar}`,
        value: Number(country[0]),
      });
    });
    Object.entries(app.coverage.pickup_cities).forEach((city: any) => {
      preSelectedCoveragePickupCities.options.push({
        label: `${city[1].name.en} - ${city[1].name.ar}`,
        value: Number(city[0]),
        country_id: Number(city[1].country_id),
      });
    });
    Object.entries(app.coverage.delivery_countries).forEach((country: any) => {
      preSelectedCoverageDeliveryCountries.options.push({
        label: `${country[1].name.en} - ${country[1].name.ar}`,
        value: Number(country[0]),
      });
    });
    Object.entries(app.coverage.delivery_cities).forEach((city: any) => {
      preSelectedCoverageDeliveryCities.options.push({
        label: `${city[1].name.en} - ${city[1].name.ar}`,
        value: Number(city[0]),
        country_id: Number(city[1].country_id),
      });
    });
  }

  const createShippingAppFormState = reactive<AboutCompanyDataInterface>({
    pickup: {
      countries: [],
      cities: [],
    },
    delivery: {
      countries: [],
      cities: [],
    },
    paymentDueDateArabic: shippingApp?.payment_due_ar ?? '',
    paymentDueDateEnglish: shippingApp?.payment_due_en ?? '',
    developerNameArabic: shippingApp?.developer_name_ar ?? '',
    developerNameEnglish: shippingApp?.developer_name_en ?? '',
    shortDescriptionArabic: shippingApp?.short_description_ar ?? '',
    shortDescriptionEnglish: shippingApp?.short_description_en ?? '',
    receivingOrdersTimesArabic: shippingApp?.times_to_pickup_ar ?? '',
    receivingOrdersTimesEnglish: shippingApp?.times_to_pickup_en ?? '',
    orderDeliveryTimesArabic: shippingApp?.times_to_deliver_ar ?? '',
    orderDeliveryTimesEnglish: shippingApp?.times_to_deliver_en ?? '',
    shippingWeightArabic: shippingApp?.weight_details_ar ?? '',
    shippingWeightEnglish: shippingApp?.weight_details_en ?? '',
    appScreenshotsAr: [...globalScreenshotsAr],
    appScreenshotsEn: [...globalScreenshotsEn],
  });

  const originalArabicScreenshots = reactive<Record<string, File[]>>({ list: [] });
  const originalEnglishScreenshots = reactive<Record<string, File[]>>({ list: [] });

  const initialArabicScreenshots = reactive({
    list: [
      ...createShippingAppFormState.appScreenshotsAr.map((screen: string | File) => {
        if (typeof screen === 'string') {
          return new File([], screen, {
            type: 'image/jpg',
          });
        } else return screen;
      }),
    ],
  });

  const initialEnglishScreenshots = reactive({
    list: [
      ...createShippingAppFormState.appScreenshotsEn.map((screen: string | File) => {
        if (typeof screen === 'string') {
          return new File([], screen, {
            type: 'image/jpg',
          });
        } else return screen;
      }),
    ],
  });

  const partnerCountriesForCoverage = reactive<ShippingCountriesType>({
    selectAll: 'Select All',
    options: [],
  });

  const partnerCountriesForDeliveryCoverage = reactive<ShippingCountriesType>({
    selectAll: 'Select All',
    options: [],
  });

  const partnerCoverageTempCities = reactive<ShippingCitiesType>({
    selectAll: 'Select All',
    options: [],
  });

  const partnerCoveragePickupCities = reactive<ShippingCitiesType>({
    selectAll: 'Select All',
    options: [],
  });

  const partnerCoverageDeliveryCities = reactive<ShippingCitiesType>({
    selectAll: 'Select All',
    options: [],
  });

  const validateOptionalField = (validationRule: RegExp) => (value: string) =>
    !helpers.req(value) || validationRule.test(value);

  const rules = {
    pickup: {
      countries: {
        required,
      },
      cities: {
        required,
      },
    },
    delivery: {
      countries: {
        required,
      },
      cities: {
        required,
      },
    },
    paymentDueDateEnglish: {
      paymentDueDateEnglish: helpers.withMessage(
        'English field can only contain English letters.',
        validateOptionalField(ENGLISH_TEXT_WITH_SPECIAL_CHARS),
      ),
    },
    paymentDueDateArabic: {
      paymentDueDateArabic: helpers.withMessage(
        'Arabic field can only contain Arabic letters.',
        validateOptionalField(ARABIC_TEXT_WITH_SPECIAL_CHARS),
      ),
    },
    receivingOrdersTimesEnglish: {
      receivingOrdersTimesEnglish: helpers.withMessage(
        'English field can only contain English letters.',
        validateOptionalField(ENGLISH_TEXT_WITH_NEW_LINE_AND_SPECIAL_CHARS),
      ),
    },
    receivingOrdersTimesArabic: {
      receivingOrdersTimesArabic: helpers.withMessage(
        'Arabic field can only contain Arabic letters.',
        validateOptionalField(ARABIC_TEXT_WITH_NEW_LINE_AND_SPECIAL_CHARS),
      ),
    },
    orderDeliveryTimesEnglish: {
      orderDeliveryTimesEnglish: helpers.withMessage(
        'English field can only contain English letters.',
        validateOptionalField(ENGLISH_TEXT_WITH_NEW_LINE_AND_SPECIAL_CHARS),
      ),
    },
    orderDeliveryTimesArabic: {
      orderDeliveryTimesArabic: helpers.withMessage(
        'Arabic field can only contain Arabic letters.',
        validateOptionalField(ARABIC_TEXT_WITH_NEW_LINE_AND_SPECIAL_CHARS),
      ),
    },
    shippingWeightEnglish: {
      shippingWeightEnglish: helpers.withMessage(
        'English field can only contain English letters.',
        validateOptionalField(ENGLISH_TEXT_WITH_SPECIAL_CHARS),
      ),
    },
    shippingWeightArabic: {
      shippingWeightArabic: helpers.withMessage(
        'Arabic field can only contain Arabic letters.',
        validateOptionalField(ARABIC_TEXT_WITH_SPECIAL_CHARS),
      ),
    },
    developerNameEnglish: {
      required,
      developerNameEnglish: helpers.withMessage('English field can only contain English letters.', (value: string) => {
        return ENGLISH_TEXT_WITH_SPECIAL_CHARS.test(value);
      }),
    },
    developerNameArabic: {
      required,
      developerNameArabic: helpers.withMessage('Arabic field can only contain Arabic letters.', (value: string) => {
        return ARABIC_TEXT_WITH_SPECIAL_CHARS.test(value);
      }),
    },
    shortDescriptionEnglish: {
      required,
      maxLength: maxLength(SHORT_DESCRIPTION_MAX_LENGTH),
      shortDescriptionEnglish: helpers.withMessage(
        'English field is required and can only contain English letters.',
        (value: string) => {
          return ENGLISH_TEXT_WITH_NEW_LINE_AND_SPECIAL_CHARS.test(value);
        },
      ),
    },
    shortDescriptionArabic: {
      required,
      maxLength: maxLength(SHORT_DESCRIPTION_MAX_LENGTH),
      shortDescriptionArabic: helpers.withMessage(
        'Arabic field is required and can only contain Arabic letters.',
        (value: string) => {
          return ARABIC_TEXT_WITH_NEW_LINE_AND_SPECIAL_CHARS.test(value);
        },
      ),
    },
    appScreenshotsAr: {},
    appScreenshotsEn: {},
  };

  const v$ = useVuelidate(rules, createShippingAppFormState);

  const onInputDataChange = (changedValue: Record<string, string>, dataProperty: AboutCompanyInputPropertiesEnum) => {
    v$.value[dataProperty].$touch();
    createShippingAppFormState[dataProperty] = changedValue.value;
  };

  const showMessageForPaymentDueDateArEnField = computed(() => {
    return (
      (v$.value.paymentDueDateArabic.$dirty || v$.value.paymentDueDateEnglish.$dirty) &&
      (v$.value.paymentDueDateArabic.$invalid || v$.value.paymentDueDateEnglish.$invalid)
    );
  });

  const showMessageForDeveloperNameArEnField = computed(() => {
    return (
      (v$.value.developerNameArabic.$dirty || v$.value.developerNameEnglish.$dirty) &&
      (v$.value.developerNameArabic.$invalid || v$.value.developerNameEnglish.$invalid)
    );
  });

  const showMessageForShortDescriptionArEnField = computed(() => {
    return (
      (v$.value.shortDescriptionArabic.$dirty || v$.value.shortDescriptionEnglish.$dirty) &&
      (v$.value.shortDescriptionArabic.$invalid || v$.value.shortDescriptionEnglish.$invalid)
    );
  });

  const showMessageForReceivingOrdersTimesArEnField = computed(() => {
    return (
      (v$.value.receivingOrdersTimesEnglish.$dirty || v$.value.receivingOrdersTimesArabic.$dirty) &&
      (v$.value.receivingOrdersTimesArabic.$invalid || v$.value.receivingOrdersTimesEnglish.$invalid)
    );
  });

  const showMessageForOrderDeliveryTimesArEnField = computed(() => {
    return (
      (v$.value.orderDeliveryTimesEnglish.$dirty || v$.value.orderDeliveryTimesArabic.$dirty) &&
      (v$.value.orderDeliveryTimesArabic.$invalid || v$.value.orderDeliveryTimesEnglish.$invalid)
    );
  });

  const showMessageForShippingWeightArEnField = computed(() => {
    return (
      (v$.value.shippingWeightArabic.$dirty || v$.value.shippingWeightEnglish.$dirty) &&
      (v$.value.shippingWeightArabic.$invalid || v$.value.shippingWeightEnglish.$invalid)
    );
  });

  const areArabicFiles = computed(() => originalArabicScreenshots.list.length);

  const isSaveDisabled = computed(() => {
    return v$.value.$invalid || isArScreenshotsInvalid.value || isEnScreenshotsInvalid.value;
  });

  const customNameForLocations = (location: { label: string; value: number }) => {
    return `${location.label}`;
  };

  watch(
    () => createShippingAppFormState.pickup.countries,
    (pickup_countries) => {
      if (pickup_countries?.length !== 0) {
        getCitiesForSelectedCountries(AboutCompanyLocationTypesEnum.Pickup);
      } else {
        partnerCoveragePickupCities.options = [];
      }
    },
    { deep: true },
  );

  watch(
    () => createShippingAppFormState.delivery.countries,
    (delivery_countries) => {
      if (delivery_countries?.length !== 0) {
        getCitiesForSelectedCountries(AboutCompanyLocationTypesEnum.Delivery);
      } else {
        partnerCoverageDeliveryCities.options = [];
      }
    },
    { deep: true },
  );

  const onSelectedCountryRemove = (
    removedCountry: { label: string; value: number },
    dataProperty: AboutCompanyLocationTypesEnum,
  ) => {
    let newCitiesAfterRemoval = null;
    if (createShippingAppFormState[dataProperty].countries) {
      // remove cities
      if (createShippingAppFormState[dataProperty].cities) {
        newCitiesAfterRemoval = createShippingAppFormState[dataProperty].cities?.filter((c) => {
          if (Array.isArray(removedCountry)) {
            createShippingAppFormState[dataProperty].countries = [];
            return !removedCountry.some(({ value }) => value === c.country_id);
          } else {
            return c.country_id != removedCountry.value;
          }
        });
        createShippingAppFormState[dataProperty].cities = newCitiesAfterRemoval ?? null;
        if (dataProperty == AboutCompanyLocationTypesEnum.Pickup) {
          preSelectedCoveragePickupCountries.options = [];
          const newPreSelectedPickupCitiesAfterRemoval = preSelectedCoveragePickupCities.options?.filter((c) => {
            if (Array.isArray(removedCountry)) {
              createShippingAppFormState[dataProperty].countries = [];
              return !removedCountry.some(({ value }) => value === c.country_id);
            } else {
              return c.country_id != removedCountry.value;
            }
          });
          preSelectedCoveragePickupCities.options = newPreSelectedPickupCitiesAfterRemoval ?? [];
          partnerCoveragePickupCities.options = partnerCoveragePickupCities.options.filter((c) => {
            if (Array.isArray(removedCountry)) {
              return !removedCountry.some(({ value }) => value === c.country_id);
            } else {
              return c.country_id != removedCountry.value;
            }
          });
        } else {
          preSelectedCoverageDeliveryCountries.options = [];
          const newPreSelectedDeliveryCitiesAfterRemoval = preSelectedCoverageDeliveryCities.options?.filter((c) => {
            if (Array.isArray(removedCountry)) {
              createShippingAppFormState[dataProperty].countries = [];
              return !removedCountry.some(({ value }) => value === c.country_id);
            } else {
              return c.country_id != removedCountry.value;
            }
          });
          preSelectedCoverageDeliveryCities.options = newPreSelectedDeliveryCitiesAfterRemoval ?? [];
          partnerCoverageDeliveryCities.options = partnerCoverageDeliveryCities.options.filter((c) => {
            if (Array.isArray(removedCountry)) {
              return !removedCountry.some(({ value }) => value === c.country_id);
            } else {
              return c.country_id != removedCountry.value;
            }
          });
        }
      }
    }
  };

  const getCitiesForSelectedCountries = async (dataProperty: AboutCompanyLocationTypesEnum) => {
    if (!shippingAppId) {
      Vue.$toast.error('Unable to find application data.');
      return;
    }
    if (createShippingAppFormState[dataProperty].countries?.length) {
      const countries = createShippingAppFormState[dataProperty].countries?.map(({ value }) => value) ?? [];

      isLoadingPickupCities.value = true;
      isLoadingDeliveryCities.value = true;
      const response = await getPartnerAvailableCoverageCities(shippingAppId, dataProperty, countries);

      if (response instanceof ErrorModel || !response.cities) {
        isLoadingPickupCities.value = false;
        isLoadingDeliveryCities.value = false;
        Vue.$toast.error('Failed to load Cities For Selected Countries');
        return;
      }

      partnerCoverageTempCities.options = [];
      const { cities } = response;
      if (cities.length > 0) {
        cities.forEach((city: PartnerAvailableCitiesModel) => {
          partnerCoverageTempCities.options.push({
            label: `${city.name.en} - ${city.name.ar}`,
            value: city.id,
            country_id: city.country_id,
          });
        });

        if (dataProperty == AboutCompanyLocationTypesEnum.Pickup) {
          partnerCoveragePickupCities.options = [...partnerCoverageTempCities.options];

          const preSelectedCities: {
            label: string;
            value: number | null;
            country_id: number | null;
          }[] = [];

          preSelectedCoveragePickupCities.options.forEach((preSelectedCity) => {
            const city = partnerCoveragePickupCities.options.find(
              (cityOption) => cityOption.value === preSelectedCity.value,
            );
            if (city) preSelectedCities.push(city);
          });

          createShippingAppFormState.pickup.cities = [...preSelectedCities];
        } else {
          partnerCoverageDeliveryCities.options = [...partnerCoverageTempCities.options];

          const preSelectedCities: {
            label: string;
            value: number | null;
            country_id: number | null;
          }[] = [];

          preSelectedCoverageDeliveryCities.options.forEach((preSelectedCity) => {
            const city = partnerCoverageDeliveryCities.options.find(
              (cityOption) => cityOption.value === preSelectedCity.value,
            );
            if (city) preSelectedCities.push(city);
          });

          createShippingAppFormState.delivery.cities = [...preSelectedCities];
        }
      }
      isLoadingPickupCities.value = false;
      isLoadingDeliveryCities.value = false;
    }
  };

  const onArabicScreenshotsChange = (images: File[]) => {
    const newImages = images.filter(
      (screen) =>
        originalArabicScreenshots.list.findIndex((originalScreen) => originalScreen.name === screen.name) == -1,
    );

    originalArabicScreenshots.list = [...originalArabicScreenshots.list, ...newImages];

    const areFiles = originalArabicScreenshots.list.every((file) => file.type.includes('image/'));

    if (images.length > MAX_SCREENSHOTS_NUMBER || images.length < MIN_SCREENSHOTS_NUMBER || !areFiles) {
      isArScreenshotsInvalid.value = true;
    } else {
      isArScreenshotsInvalid.value = false;
    }
  };

  const onArabicScreenshotsRemove = (image: File) => {
    const screenIndex = originalArabicScreenshots.list.findIndex((screen) => {
      return screen.name === image.name;
    });

    if (screenIndex !== -1) {
      originalArabicScreenshots.list.splice(screenIndex, 1);
      const areFiles = originalArabicScreenshots.list.every((file) => file.type.includes('image/'));

      if (
        originalArabicScreenshots.list.length > MAX_SCREENSHOTS_NUMBER ||
        originalArabicScreenshots.list.length < MIN_SCREENSHOTS_NUMBER ||
        !areFiles
      ) {
        isArScreenshotsInvalid.value = true;
      } else {
        isArScreenshotsInvalid.value = false;
      }
    }
  };

  const onEnglishScreenshotsChange = (images: File[]) => {
    const newImages = images.filter(
      (screen) =>
        originalEnglishScreenshots.list.findIndex((originalScreen) => originalScreen.name === screen.name) == -1,
    );

    originalEnglishScreenshots.list = [...originalEnglishScreenshots.list, ...newImages];
    const areFiles = originalEnglishScreenshots.list.every((file) => file.type.includes('image/'));

    if (images.length > MAX_SCREENSHOTS_NUMBER || !areFiles) {
      isEnScreenshotsInvalid.value = true;
    } else {
      isEnScreenshotsInvalid.value = false;
    }
    return [];
  };

  const onEnglishScreenshotsRemove = (image: File) => {
    const screenIndex = originalEnglishScreenshots.list.findIndex((screen) => {
      return screen.name === image.name;
    });

    if (screenIndex !== -1) {
      originalEnglishScreenshots.list.splice(screenIndex, 1);
      const areFiles = originalEnglishScreenshots.list.every((file) => file.type.includes('image/'));

      if (originalEnglishScreenshots.list.length > MAX_SCREENSHOTS_NUMBER || !areFiles) {
        isEnScreenshotsInvalid.value = true;
      } else {
        isEnScreenshotsInvalid.value = false;
      }
    }
  };

  const getArabicScreenshotsFiles = async () => {
    return Promise.all(
      createShippingAppFormState.appScreenshotsAr.map(async (screen) => {
        if (typeof screen === 'string') {
          const sc = await getFileFromURL(screen, screen, 'image/jpg');
          return sc;
        } else return screen;
      }),
    )
      .then((values) => {
        return values;
      })
      .catch(() => {
        initialArabicScreenshots.list = [];
        return [];
      });
  };

  const getEnglishScreenshotsFiles = async () => {
    return Promise.all(
      createShippingAppFormState.appScreenshotsEn.map(async (screen) => {
        if (typeof screen === 'string') {
          const sc = await getFileFromURL(screen, screen, 'image/jpg');
          return sc;
        } else return screen;
      }),
    )
      .then((values) => {
        return values;
      })
      .catch(() => {
        initialEnglishScreenshots.list = [];
        return [];
      });
  };

  onBeforeMount(async () => {
    if (shippingAppId) {
      isInitialArabicScreenshotsLoading.value = true;
      originalArabicScreenshots.list = await getArabicScreenshotsFiles();
      if (createShippingAppFormState.appScreenshotsAr instanceof Array) {
        initialArabicScreenshots.list = [
          ...createShippingAppFormState.appScreenshotsAr.map((screen: string | File) => {
            if (typeof screen === 'string') {
              return new File([], screen, {
                type: 'image/jpg',
              });
            } else return screen;
          }),
        ];
      }
      isInitialArabicScreenshotsLoading.value = false;

      isLoadingPickupCountries.value = true;
      isLoadingDeliveryCountries.value = true;

      const response = await getPartnerCountriesForCoverage(shippingAppId);
      if (response instanceof ErrorModel || !response.countries) {
        isLoadingPickupCountries.value = false;
        isLoadingDeliveryCountries.value = false;
        Vue.$toast.error('Failed to load Countries For Coverage');
        router.back();
        return;
      }
      partnerCountriesForCoverage.options = [];
      partnerCountriesForDeliveryCoverage.options = [];
      const { countries } = response;
      if (countries.length > 0) {
        countries.forEach((country: PartnerAvailableCountriesModel) => {
          partnerCountriesForCoverage.options.push({
            label: `${country.name.en} - ${country.name.ar}`,
            value: country.id,
          });
          partnerCountriesForDeliveryCoverage.options.push({
            label: `${country.name.en} - ${country.name.ar}`,
            value: country.id,
          });
        });

        // pickup
        const preSelectedCountries:
          | {
              label: string;
              value: number | null;
            }[]
          | null = [];
        preSelectedCoveragePickupCountries.options.forEach((preSelectedPickupCountry) => {
          const country = partnerCountriesForCoverage.options.find(
            (countryOption) => preSelectedPickupCountry.value === countryOption.value,
          );
          if (country) preSelectedCountries.push(country);
        });
        createShippingAppFormState.pickup.countries = [...preSelectedCountries];
        isLoadingPickupCountries.value = false;
        // pickup
        // delivery
        const preSelectedDeliveryCountries:
          | {
              label: string;
              value: number | null;
            }[]
          | null = [];
        preSelectedCoverageDeliveryCountries.options.forEach((preSelectedDeliveryCountry) => {
          const country = partnerCountriesForDeliveryCoverage.options.find(
            (countryOption) => preSelectedDeliveryCountry.value === countryOption.value,
          );
          if (country) preSelectedDeliveryCountries.push(country);
        });
        createShippingAppFormState.delivery.countries = [...preSelectedDeliveryCountries];
        isLoadingDeliveryCountries.value = false;
        // delivery
      } else {
        isLoadingPickupCountries.value = false;
        isLoadingDeliveryCountries.value = false;
        Vue.$toast.error('No Country is available For Coverage');
      }
    } else {
      isLoadingPickupCountries.value = false;
      isLoadingDeliveryCountries.value = false;
      Vue.$toast.error('Unable to load app data');
    }
  });

  onMounted(async () => {
    // get cities for pre-selected countries (edit case)
    if (createShippingAppFormState.pickup.countries) {
      getCitiesForSelectedCountries(AboutCompanyLocationTypesEnum.Pickup);
    }

    if (createShippingAppFormState.delivery.countries) {
      getCitiesForSelectedCountries(AboutCompanyLocationTypesEnum.Delivery);
    }

    if (shippingAppId) {
      isInitialEnglishScreenshotsLoading.value = true;
      originalEnglishScreenshots.list = await getEnglishScreenshotsFiles();
      if (createShippingAppFormState.appScreenshotsEn instanceof Array) {
        initialEnglishScreenshots.list = [
          ...createShippingAppFormState.appScreenshotsEn.map((screen: string | File) => {
            if (typeof screen === 'string') {
              return new File([], screen, {
                type: 'image/jpg',
              });
            } else return screen;
          }),
        ];
      }
      isInitialEnglishScreenshotsLoading.value = false;
    }
  });

  return {
    v$,
    shippingAppId,
    createShippingAppFormState,
    partnerCountriesForCoverage,
    partnerCountriesForDeliveryCoverage,
    partnerCoverageDeliveryCities,
    partnerCoveragePickupCities,
    originalArabicScreenshots,
    originalEnglishScreenshots,
    areArabicFiles,
    isArScreenshotsInvalid,
    isEnScreenshotsInvalid,
    initialArabicScreenshots,
    initialEnglishScreenshots,
    isInitialArabicScreenshotsLoading,
    isInitialEnglishScreenshotsLoading,
    isSaveDisabled,
    SHORT_DESCRIPTION_MAX_LENGTH,
    showMessageForDeveloperNameArEnField,
    showMessageForPaymentDueDateArEnField,
    showMessageForShortDescriptionArEnField,
    showMessageForReceivingOrdersTimesArEnField,
    showMessageForOrderDeliveryTimesArEnField,
    showMessageForShippingWeightArEnField,
    AboutCompanyLocationTypesEnum,
    AboutCompanyInputPropertiesEnum,
    isLoadingPickupCountries,
    isLoadingDeliveryCountries,
    isLoadingPickupCities,
    isLoadingDeliveryCities,
    onInputDataChange,
    onArabicScreenshotsChange,
    onEnglishScreenshotsChange,
    onSelectedCountryRemove,
    onArabicScreenshotsRemove,
    onEnglishScreenshotsRemove,
    customNameForLocations,
  };
};

export default useAboutCompany;
