export enum ShippingServicesLabelsEnum {
  RefrigeratedShipping = 'Refrigerated Shipping',
  HeavyShipping = 'Heavy Shipping',
}

export enum ShippingServicesValuesEnum {
  False = 'false',
}

export enum CompanyTypesLabelsEnum {
  ShippingAndFulfillment = 'Shipping and fullfilments company',
  ShippingOnly = 'Shipping company only',
}

export enum CompanyTypesValuesEnum {
  Fulfillment = 'fulfillment',
  Shipping = 'shipping',
}
export enum SupportedCurrenciesLabelsEnum {
  SAR = 'SAR',
}

export enum SupportedCurrenciesValuesEnum {
  SAR = 'SAR',
}
export enum SupportedWeightLabelsEnum {
  KG = 'KG',
}

export enum SupportedWeightValuesEnum {
  KG = 'KG',
}

export enum CreateShippingAppInputPropertiesEnum {
  AppCompanyNameEnglish = 'appCompanyNameEnglish',
  AppCompanyNameArabic = 'appCompanyNameArabic',
  CompanyURL = 'companyURL',
  AboutCompanyEnglish = 'aboutCompanyEnglish',
  AboutCompanyArabic = 'aboutCompanyArabic',
  MobileNumber = 'mobileNumber',
  TechnicalSupportEmail = 'technicalSupportEmail',
}

export enum CreateShippingAppSelectPropertyEnum {
  TypeOfCompany = 'typeOfCompany',
}

export enum GeneralSettingsInputPropertiesEnum {
  RedirectUrl = 'redirectUrl',
  CallbackUrl = 'callbackUrl',
}

export type GeneralSettingsInfoType = {
  step: number;
  app_id: number | null;
  callback_url: string;
  redirect_url: string;
};

export enum AboutCompanyInputPropertiesEnum {
  PaymentDueDateEnglish = 'paymentDueDateEnglish',
  PaymentDueDateArabic = 'paymentDueDateArabic',
  DeveloperNameEnglish = 'developerNameEnglish',
  DeveloperNameArabic = 'developerNameArabic',
  ShortDescriptionEnglish = 'shortDescriptionEnglish',
  ShortDescriptionArabic = 'shortDescriptionArabic',
  ShippingWeightEnglish = 'shippingWeightEnglish',
  ShippingWeightArabic = 'shippingWeightArabic',
  ReceivingOrdersTimesEnglish = 'receivingOrdersTimesEnglish',
  ReceivingOrdersTimesArabic = 'receivingOrdersTimesArabic',
  OrderDeliveryTimesEnglish = 'orderDeliveryTimesEnglish',
  OrderDeliveryTimesArabic = 'orderDeliveryTimesArabic',
}

export enum AboutCompanyLocationTypesEnum {
  Pickup = 'pickup',
  Delivery = 'delivery',
}

export enum ShippingOptionDetailsInputPropertiesEnum {
  ActivationURL = 'activationURL',
  CustomizationNameEn = 'customizationNameEn',
  CustomizationNameAr = 'customizationNameAr',
  CodCost = 'CodCost',
  ShippingCost = 'shippingCost',
  BaseWeight = 'baseWeight',
  BaseWeightCost = 'baseWeightCost',
  AdditionalWeight = 'additionalWeight',
  AdditionalWeightCost = 'additionalWeightCost',
}

export enum CustomizationOptionLabelsEnum {
  Activated = 'Activated',
  NotActivated = 'Not Activated',
  Fixed = 'fixed',
  FixedPrice = 'Fixed Price',
  ByWeight = 'By Weight',
}

export type ShippingAppDetailsInfoType = {
  payment_due_en: string;
  payment_due_ar: string;
  locations_from: (number | null)[];
  locations_to: (number | null)[];
  times_to_pickup_en: string;
  times_to_pickup_ar: string;
  times_to_deliver_en: string;
  times_to_deliver_ar: string;
  weight_details_en: string;
  weight_details_ar: string;
  developer_name_en: string;
  developer_name_ar: string;
  short_description_en: string;
  short_description_ar: string;
  screen_shots_en: (string | File)[];
  screen_shots_ar: (string | File)[];
};

export enum ActivationAndPricingMechanismEnum {
  RequestActivation = 'requestActivation',
  ActivateImmediately = 'activateImmediately',
}

export enum SelectedPriceEnum {
  Free = 'free',
  Paid = 'paid',
}

export enum PricingTypesEnum {
  Fixed = 'fixed',
  ByWeight = 'weight_based',
}

export type CustomizationDetailsType = {
  id: number | null;
  name: {
    ar: string;
    en: string;
  };
  cities: (number | null)[];
  is_cod_allowed: boolean;
  pricing_type: string;
  rate: {
    cost?: string;
    cod_cost: string;
    base_weight?: string;
    base_weight_cost?: string;
    additional_weight?: string;
    additional_weight_cost?: string;
  };
};

export type ShippingCountriesType = {
  selectAll?: string;
  options: {
    label: string;
    value: number;
  }[];
};

export type ShippingCitiesType = {
  selectAll?: string;
  options: {
    label: string;
    value: number;
    country_id: number;
  }[];
};

export enum WebhooksManagmentInputPropertiesEnum {
  TargetURL = 'targetURL',
  Headers = 'headers',
}

export enum SubscribtionTypesEnum {
  Orders = 'orders',
  Subscription = 'subscription',
  Products = 'products',
  Sync = 'sync',
}

export type SubscribtionDetailsType = {
  id: number;
  webhookSelection: string[];
  targetURL: string;
  headers: string;
};

export type SubscribtionType = {
  orders: SubscribtionDetailsType;
  subscription: SubscribtionDetailsType;
  products: SubscribtionDetailsType;
  sync: SubscribtionDetailsType;
};

export type WebhookGroup = {
  group_id: string | null;
  webhooks: WebhookType[];
};

export type WebhookType = {
  id: number | null;
  name: string;
  target_url: string;
  headers: string;
  conditions?: Record<string, string>;
  group_type?: string;
};

export type WebhookResponse = {
  id: number | null;
  name: string;
  target_url: string;
  headers: string;
  conditions?: Record<string, string>;
  app_id: number;
  group_type: string;
};

export type WebhooksFromSaveBtnType = {
  orders: boolean;
  subscription: boolean;
  products: boolean;
  sync: boolean;
};

export type AvailableWebhooksType = {
  orders: string[];
  subscription: string[];
  products: string[];
  sync: string[];
};

export type AvailableWebhooksOptionsType = {
  options: AvailableWebhooksType;
};
