import BaseModel from '@/api/models/BaseModel';
import { WebhookResponse } from '../types/create-app/createApp.enum';

class AddWebhookGroupResponseModel extends BaseModel {
  public webhooks: Record<string, WebhookResponse[]>;

  constructor(arg: any) {
    super(arg);
    this.webhooks = arg.webhooks;
  }
}

export default AddWebhookGroupResponseModel;
