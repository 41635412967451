import Vue from 'vue';
import { defineComponent, onBeforeMount, reactive, ref } from '@vue/composition-api';
import {
  getDevelopmentStores,
  installAppOnDevelopmentStore,
} from '@/domains/development-store/api/development-store/developmentStore';
import ErrorModel from '@/api/models/ErrorModel';
import { DevelopmentStoresList, AppInstallationStatus } from '@/domains/development-store/types/developmentStore.type';
import {
  ZidButton,
  ZidLoader,
  ZidBadge,
  ZidTable,
  ZidTableRowGroup,
  ZidTableHeader,
  ZidTableRow,
  ZidTableCell,
} from '@zidsa/ui';
import BaseText from '@/components/text/BaseText.vue';
import { RoutingRouteEnum } from '@/router/routes.enum';

export default defineComponent({
  props: {
    appId: Number,
    appLink: String,
  },
  components: {
    BaseText,
    ZidButton,
    ZidLoader,
    ZidBadge,
    ZidTable,
    ZidTableRowGroup,
    ZidTableHeader,
    ZidTableRow,
    ZidTableCell,
  },
  setup(props) {
    const isFetchingStoresList = ref(false);
    const isInstallingApp = ref(false);
    const storesList = reactive<DevelopmentStoresList>({ list: [] });

    onBeforeMount(async () => {
      await fetchDevelopmentStoresList();
    });

    const fetchDevelopmentStoresList = async () => {
      isFetchingStoresList.value = true;
      const response = await getDevelopmentStores(props.appId);
      if (response instanceof ErrorModel || !response.payload) {
        isFetchingStoresList.value = false;
        return;
      }
      storesList.list = response.payload;
      isFetchingStoresList.value = false;
    };

    const onInstallAppClicked = async (storeId: number) => {
      isInstallingApp.value = true;
      const appId = props.appId ?? null;
      if (!appId) {
        Vue.$toast.error('Unable install this app at the moment');
        isInstallingApp.value = false;
        return;
      }
      const response = await installAppOnDevelopmentStore(storeId, appId);
      if (response instanceof ErrorModel) {
        Vue.$toast.error('Error installing the application on development store');
        isInstallingApp.value = false;
        return;
      }
      Vue.$toast.success('Application was installed successfully');
      await fetchDevelopmentStoresList();
      isInstallingApp.value = false;
    };

    return {
      isFetchingStoresList,
      isInstallingApp,
      storesList,
      AppInstallationStatus,
      DevelopmentStoreRoute: RoutingRouteEnum.DevelopmentStore,
      onInstallAppClicked,
    };
  },
});
