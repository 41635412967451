import httpClient from '@/api/httpClient';
import ErrorModel from '@/api/models/ErrorModel';
import { WebhookGroup } from '@/domains/shipping/types/create-app/createApp.enum';
import PartnerWebhooksResponseModel from '@/domains/shipping/models/PartnerWebhooksResponseModel';
import AddWebhookGroupResponseModel from '@/domains/shipping/models/AddWebhookGroupResponseModel';
import DeleteWebhookResponseModel from '@/domains/shipping/models/DeleteWebhookResponseModel';

const ENDPOINT_URL = '/market';

export const getAppWebhooksData = (app_id: number): Promise<PartnerWebhooksResponseModel | ErrorModel> => {
  return httpClient
    .get(`${ENDPOINT_URL}/shipping/webhooks/${app_id}`)
    .then((response) => new PartnerWebhooksResponseModel(response))
    .catch((error) => error);
};

export const setWebhookGroupDataToApi = (
  webhookGroupInfo: WebhookGroup,
  shippingAppId: number,
): Promise<AddWebhookGroupResponseModel | ErrorModel> => {
  return httpClient
    .post(`${ENDPOINT_URL}/update/multiple/${shippingAppId}/webhook`, webhookGroupInfo)
    .then((response) => new AddWebhookGroupResponseModel(response))
    .catch((error) => error);
};

export const deleteWebhookGroup = (
  webhookGroupID: string,
  shippingAppId: number,
): Promise<DeleteWebhookResponseModel | ErrorModel> => {
  return httpClient
    .delete(`${ENDPOINT_URL}/delete/${shippingAppId}/webhook/group`, {
      data: {
        webhook: {
          group_id: webhookGroupID,
        },
      },
    })
    .then((response) => new DeleteWebhookResponseModel(response))
    .catch((error) => error);
};
