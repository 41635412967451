import Vue from 'vue';
import { computed, reactive, ref, watch, onBeforeMount } from '@vue/composition-api';
import useVuelidate from '@vuelidate/core';
import { required, helpers } from '@vuelidate/validators';
import { isValidURL } from '@/helpers/regex/URLs';
import { ShippingOptionDetailsInterface } from '@/store/modules/shipping/applicationState.type';
import {
  PricingTypesEnum,
  SelectedPriceEnum,
  ShippingCitiesType,
  ShippingCountriesType,
  CustomizationDetailsType,
  CustomizationOptionLabelsEnum,
  ActivationAndPricingMechanismEnum,
  ShippingOptionDetailsInputPropertiesEnum,
} from '@/domains/shipping/types/create-app/createApp.enum';
import {
  SUPPORTED_CURRENCIES_FOR_CUSTOMIZATION,
  SUPPORTED_WEIGHTS_FOR_CUSTOMIZATION,
} from '@/domains/shipping/helper/dropdownInfo';
import { ARABIC_TEXT_WITH_SPECIAL_CHARS, ENGLISH_TEXT_WITH_SPECIAL_CHARS } from '@/helpers/regex/text';
import { getPartnerAvailableCountriesForCustomization } from '@/domains/shipping/api/partnerAvailableCountriesForCustomization';
import { getPartnerAvailableCitiesForCustomization } from '@/domains/shipping/api/partnerAvailableCitiesForCustomization';
import PartnerAvailableCountriesModel from '@/domains/shipping/models/PartnerAvailableCountriesModel';
import PartnerAvailableCitiesModel from '@/domains/shipping/models/PartnerAvailableCitiesModel';
import { changePricingMechanism } from '@/domains/shipping/api/steps/shipping-option-details/changePricingMechanism';
import { setCustomizationDetailsToApi } from '@/domains/shipping/api/steps/shipping-option-details/customizationDetails';
import { getPartnerCustomizations } from '@/domains/shipping/api/steps/shipping-option-details/getPartnerCustomizations';
import { getPartnerSingleCustomizationDetails } from '@/domains/shipping/api/steps/shipping-option-details/getPartnerSingleCustomizationDetails';
import { deletePartnerSingleCustomization } from '@/domains/shipping/api/steps/shipping-option-details/deletePartnerSingleCustomization';
import ErrorModel from '@/api/models/ErrorModel';
import CustomizationModel from '@/domains/shipping/models/CustomizationModel';
import { RoutingRouteEnum } from '@/router/routes.enum';
import { createNamespacedHelpers } from 'vuex-composition-helpers';

const { useGetters, useActions } = createNamespacedHelpers('shipping');

const useShippingOptionDetails = () => {
  const { setInProgressFullApplication, removeStepsCompleted } = useActions([
    'setInProgressFullApplication',
    'removeStepsCompleted',
  ]);

  let contract_link = '';
  let shippingAppId: number | null = null;
  const selectedMechanism = ref(ActivationAndPricingMechanismEnum.RequestActivation);
  const selectedCODPricingType = ref('free');
  const selectedFixedShippingPricingType = ref('free');
  const loadingCustomizationList = ref(false);
  const showAddCustomizationMsg = ref(true);
  const showAddCustomizationForm = ref(false);
  const showChangePricingMechanismModal = ref(false);
  const currentlySelectedCustomizationId = ref();
  const isAddNewCustomizationBtnDisable = ref(false);
  const loadingChangePricingMechanism = ref(false);
  const loadingDeleteCustomization = ref(false);
  const showDeleteCustomizationModal = ref(false);
  const loadCustomizationDataInForm = ref(false);
  const isLoadingCustomizationCountries = ref(true);
  const isLoadingCustomizationCities = ref(false);

  const customizationList: Record<string, CustomizationModel[]> = reactive({ items: [] });

  const { inProgressFullApp } = useGetters(['inProgressFullApp']);

  if (inProgressFullApp.value) {
    const {
      payload: { app },
    } = inProgressFullApp.value;
    shippingAppId = app.app_id;
    contract_link = app.contract_link;
    if (contract_link == null || contract_link == '') {
      selectedMechanism.value = ActivationAndPricingMechanismEnum.ActivateImmediately;
    } else {
      selectedMechanism.value = ActivationAndPricingMechanismEnum.RequestActivation;
    }
  }

  const shippingOptionsFormState = reactive<ShippingOptionDetailsInterface>({
    activationURL: contract_link ?? '',
    customizationNameEn: '',
    customizationNameAr: '',
    locations: {
      countries: null,
      cities: null,
    },
    cashOnDelivery: false,
    shippingPriceType: '',
    CodCost: '0',
    shippingCost: '0',
    baseWeight: '0',
    baseWeightCost: '0',
    additionalWeight: '0',
    additionalWeightCost: '0',
  });

  const rules = {
    activationURL: {},
    customizationNameEn: {
      customizationNameEn: helpers.withMessage(
        'Customization name is required in both English & Arabic',
        (value: string) => {
          return ENGLISH_TEXT_WITH_SPECIAL_CHARS.test(value);
        },
      ),
    },
    customizationNameAr: {
      customizationNameAr: helpers.withMessage(
        'Customization name is required in both English & Arabic',
        (value: string) => {
          return ARABIC_TEXT_WITH_SPECIAL_CHARS.test(value);
        },
      ),
    },
    locations: {
      countries: {
        required,
      },
      cities: {
        required,
      },
    },
    shippingPriceType: {
      required,
    },
    cashOnDelivery: {},
    CodCost: {},
    shippingCost: {},
    baseWeight: {},
    baseWeightCost: {},
    additionalWeight: {},
    additionalWeightCost: {},
  };

  const partnerCustomizationCountries = reactive<ShippingCountriesType>({
    selectAll: 'All',
    options: [],
  });

  const partnerCustomizationTempCities = reactive<ShippingCitiesType>({
    selectAll: 'All',
    options: [],
  });

  const partnerCustomizationCities = reactive<ShippingCitiesType>({
    selectAll: 'All',
    options: [],
  });

  const preSelectedCustomizationCountries = reactive<ShippingCountriesType>({
    selectAll: 'All',
    options: [],
  });

  const preSelectedCustomizationCities = reactive<ShippingCitiesType>({
    selectAll: 'All',
    options: [],
  });

  const currencies = reactive({
    options: SUPPORTED_CURRENCIES_FOR_CUSTOMIZATION,
  });

  const weightUnits = reactive({
    options: SUPPORTED_WEIGHTS_FOR_CUSTOMIZATION,
  });

  const v$ = useVuelidate(rules, shippingOptionsFormState);

  const isSaveBtnDisable = computed(() => {
    if (selectedMechanism.value === ActivationAndPricingMechanismEnum.RequestActivation) {
      return !isValidURL(shippingOptionsFormState.activationURL);
    } else if (selectedMechanism.value === ActivationAndPricingMechanismEnum.ActivateImmediately) {
      return customizationList.items.length > 0 ? false : true;
    }
  });

  const isSaveCustomizationBtnDisable = computed(() => {
    return v$.value.$invalid;
  });

  const isUrlInvalid = computed(() => {
    return shippingOptionsFormState.activationURL ? isValidURL(shippingOptionsFormState.activationURL) : true;
  });

  const onPricingMechanismChange = (e: Event) => {
    e.preventDefault();
    if (!shippingOptionsFormState.activationURL && customizationList.items.length === 0) {
      switchSelectedPricingMechanism();
      return;
    }
    toggleChangePricingMechanismModal();
  };

  const toggleAddCustomizationMsg = () => (showAddCustomizationMsg.value = !showAddCustomizationMsg.value);

  const toggleDeleteCustomizationModal = () => {
    showDeleteCustomizationModal.value = !showDeleteCustomizationModal.value;
  };

  const toggleAddCustomizationForm = () => (showAddCustomizationForm.value = !showAddCustomizationForm.value);

  const toggleChangePricingMechanismModal = () => {
    showChangePricingMechanismModal.value = !showChangePricingMechanismModal.value;
  };

  const onInputDataChange = (
    changedValue: Record<string, string>,
    dataProperty: ShippingOptionDetailsInputPropertiesEnum,
  ) => {
    v$.value[dataProperty].$touch();
    shippingOptionsFormState[dataProperty] = changedValue.value;
  };

  const customNameForLocations = (location: { label: string; value: number }) => {
    return `${location.label}`;
  };

  const onCheckBoxValueChange = () => {
    shippingOptionsFormState.cashOnDelivery = !shippingOptionsFormState.cashOnDelivery;
  };

  const onShippingPricingTypeRadioClick = (changedValue: Record<string, string>) => {
    shippingOptionsFormState.shippingPriceType = changedValue.value;
  };

  const onCODPricingTypeRadioClick = (changedValue: Record<string, string>) => {
    selectedCODPricingType.value = changedValue.value;
  };

  const onShippingFixedPricingRadioClick = (changedValue: Record<string, string>) => {
    selectedFixedShippingPricingType.value = changedValue.value;
  };

  const onResetCustomizationForm = () => {
    shippingOptionsFormState.customizationNameEn = '';
    shippingOptionsFormState.customizationNameAr = '';
    shippingOptionsFormState.locations.countries = null;
    shippingOptionsFormState.locations.cities = null;
    shippingOptionsFormState.cashOnDelivery = false;
    shippingOptionsFormState.shippingPriceType = '';
    shippingOptionsFormState.CodCost = '0';
    shippingOptionsFormState.shippingCost = '0';
    shippingOptionsFormState.baseWeight = '0';
    shippingOptionsFormState.baseWeightCost = '0';
    shippingOptionsFormState.additionalWeight = '0';
    shippingOptionsFormState.additionalWeightCost = '0';
    v$.value.$reset();
    // reset temp values
    currentlySelectedCustomizationId.value = null;
    preSelectedCustomizationCountries.options = [];
    preSelectedCustomizationCities.options = [];
    // allow user to add new customizations
    isAddNewCustomizationBtnDisable.value = false;
    toggleAddCustomizationForm();
  };

  const onSaveCustomization = async () => {
    const customizationsDetails: CustomizationDetailsType = {
      id: currentlySelectedCustomizationId.value,
      name: {
        ar: shippingOptionsFormState.customizationNameAr,
        en: shippingOptionsFormState.customizationNameEn,
      },
      cities:
        shippingOptionsFormState.locations.cities?.map((c) => {
          return c.value;
        }) ?? [],
      is_cod_allowed: shippingOptionsFormState.cashOnDelivery,
      pricing_type: shippingOptionsFormState.shippingPriceType,
      rate: {
        cod_cost: shippingOptionsFormState.CodCost,
      },
    };
    if (shippingOptionsFormState.shippingPriceType === PricingTypesEnum.Fixed) {
      Object.assign(customizationsDetails.rate, { cost: shippingOptionsFormState.shippingCost });
    } else if (shippingOptionsFormState.shippingPriceType === PricingTypesEnum.ByWeight) {
      Object.assign(customizationsDetails.rate, {
        base_weight: shippingOptionsFormState.baseWeight,
        base_weight_cost: shippingOptionsFormState.baseWeightCost,
        additional_weight: shippingOptionsFormState.additionalWeight,
        additional_weight_cost: shippingOptionsFormState.additionalWeightCost,
      });
    }
    if (shippingAppId) {
      const response = await setCustomizationDetailsToApi(shippingAppId, customizationsDetails);
      if (response.status === 'success') {
        Vue.$toast.success('Customization Added Successfully');
        onResetCustomizationForm();
        loadSavedCustomizations();
      } else {
        Vue.$toast.error('Failed to create the customization');
      }
    }
  };

  const onEditCustomizationClick = async (customizationId: number) => {
    isAddNewCustomizationBtnDisable.value = true;
    showAddCustomizationForm.value = true;
    loadCustomizationDataInForm.value = true;
    currentlySelectedCustomizationId.value = customizationId;
    if (shippingAppId) {
      const response = await getPartnerSingleCustomizationDetails(shippingAppId, customizationId);
      loadCustomizationDataInForm.value = false;
      if (response.customization) {
        preSelectedCustomizationCities.options = [];
        preSelectedCustomizationCountries.options = [];
        Object.entries(response.customization.countries).forEach((country: any) => {
          preSelectedCustomizationCountries.options.push({
            label: `${country[1].en} - ${country[1].ar}`,
            value: Number(country[0]),
          });
        });
        Object.entries(response.customization.cities).forEach((city: any) => {
          preSelectedCustomizationCities.options.push({
            label: `${city[1].name.en} - ${city[1].name.ar}`,
            value: Number(city[0]),
            country_id: Number(city[1].country_id),
          });
        });
        shippingOptionsFormState.customizationNameEn = response.customization.name.en;
        shippingOptionsFormState.customizationNameAr = response.customization.name.ar;
        shippingOptionsFormState.cashOnDelivery = response.customization.is_cod_allowed;
        shippingOptionsFormState.shippingPriceType = response.customization.pricing_type;
        shippingOptionsFormState.CodCost = response.customization.rate.cod_cost;
        shippingOptionsFormState.shippingCost = response.customization.rate.cost;
        shippingOptionsFormState.baseWeight = `${response.customization.rate.base_weight}`;
        shippingOptionsFormState.baseWeightCost = response.customization.rate.base_weight_cost;
        shippingOptionsFormState.additionalWeight = `${response.customization.rate.additional_weight}`;
        shippingOptionsFormState.additionalWeightCost = response.customization.rate.additional_weight_cost;
        response.customization.rate.cod_cost > 0
          ? (selectedCODPricingType.value = SelectedPriceEnum.Paid)
          : (selectedCODPricingType.value = SelectedPriceEnum.Free);
        response.customization.rate.cost > 0
          ? (selectedFixedShippingPricingType.value = SelectedPriceEnum.Paid)
          : (selectedFixedShippingPricingType.value = SelectedPriceEnum.Free);

        isLoadingCustomizationCountries.value = true;
        const preSelectedCountries:
          | {
              label: string;
              value: number | null;
            }[]
          | null = [];
        preSelectedCustomizationCountries.options.forEach((preSelectedPickupCountry) => {
          const country = partnerCustomizationCountries.options.find(
            (countryOption) => preSelectedPickupCountry.value === countryOption.value,
          );
          if (country) preSelectedCountries.push(country);
        });
        shippingOptionsFormState.locations.countries = [...preSelectedCountries];
        isLoadingCustomizationCountries.value = false;

        isLoadingCustomizationCities.value = true;
        const preSelectedCities: {
          label: string;
          value: number | null;
          country_id: number | null;
        }[] = [];

        preSelectedCustomizationCities.options.forEach((preSelectedCity) => {
          const city = partnerCustomizationCities.options.find(
            (cityOption) => cityOption.value === preSelectedCity.value,
          );
          if (city) preSelectedCities.push(city);
        });
        shippingOptionsFormState.locations.cities = [...preSelectedCities];
        isLoadingCustomizationCities.value = false;
      }
    }
  };

  const onDeleteCustomizationClick = (customizationId: number) => {
    currentlySelectedCustomizationId.value = customizationId;
    toggleDeleteCustomizationModal();
  };

  const onDeleteSingleCustomizationConfirmationClicked = async () => {
    loadingDeleteCustomization.value = true;
    if (shippingAppId) {
      const response = await deletePartnerSingleCustomization(shippingAppId, currentlySelectedCustomizationId.value);
      if (response.status === 'success') {
        toggleDeleteCustomizationModal();
        // get all customizations.
        loadSavedCustomizations();
        // reset the selected customization after deletion
        currentlySelectedCustomizationId.value = null;
      }
    }
    loadingDeleteCustomization.value = false;
  };

  const switchSelectedPricingMechanism = () => {
    if (selectedMechanism.value === ActivationAndPricingMechanismEnum.ActivateImmediately) {
      selectedMechanism.value = ActivationAndPricingMechanismEnum.RequestActivation;
    } else {
      selectedMechanism.value = ActivationAndPricingMechanismEnum.ActivateImmediately;
    }
  };

  const onChangePricingMechanismConfirmationClicked = async () => {
    loadingChangePricingMechanism.value = true;
    shippingOptionsFormState.activationURL = '';
    if (shippingAppId) {
      const response = await changePricingMechanism(shippingAppId);
      if (response instanceof ErrorModel || !response.payload) {
        Vue.$toast.error('Failed to update application details');
        return;
      }
      const newFullApp = {
        payload: { ...response.payload },
      };
      setInProgressFullApplication(newFullApp);
      switchSelectedPricingMechanism();
      loadingChangePricingMechanism.value = false;
      // delete all prrviously selected configurations.
      toggleChangePricingMechanismModal();
    }
  };

  const showMessageForCustomizationNameArEnField = computed(() => {
    return (
      (v$.value.customizationNameAr.$dirty || v$.value.customizationNameEn.$dirty) &&
      (v$.value.customizationNameAr.$invalid || v$.value.customizationNameEn.$invalid)
    );
  });

  const onSelectedCountryRemove = (removedCountry: { label: string; value: number }) => {
    let newCitiesAfterRemoval = null;
    if (shippingOptionsFormState.locations.countries) {
      isLoadingCustomizationCities.value = true;
      // remove cities
      if (shippingOptionsFormState.locations.cities) {
        newCitiesAfterRemoval = shippingOptionsFormState.locations.cities?.filter((c) => {
          if (Array.isArray(removedCountry)) {
            shippingOptionsFormState.locations.countries = [];
            return !removedCountry.some(({ value }) => value === c.country_id);
          } else {
            return c.country_id != removedCountry.value;
          }
        });
        shippingOptionsFormState.locations.cities = newCitiesAfterRemoval ?? null;
        preSelectedCustomizationCities.options = [];
        const newPreSelectedCitiesAfterRemoval = preSelectedCustomizationCities.options?.filter((c) => {
          if (Array.isArray(removedCountry)) {
            shippingOptionsFormState.locations.countries = [];
            return !removedCountry.some(({ value }) => value === c.country_id);
          } else {
            return c.country_id != removedCountry.value;
          }
        });
        preSelectedCustomizationCities.options = newPreSelectedCitiesAfterRemoval ?? [];
        partnerCustomizationCities.options = partnerCustomizationCities.options.filter((c) => {
          if (Array.isArray(removedCountry)) {
            return !removedCountry.some(({ value }) => value === c.country_id);
          } else {
            return c.country_id != removedCountry.value;
          }
        });
      }
      isLoadingCustomizationCities.value = false;
    }
  };

  const getCitiesForSelectedCountries = async () => {
    if (shippingAppId && shippingOptionsFormState.locations.countries) {
      const countries = shippingOptionsFormState.locations.countries.map(({ value }) => value);
      const response = await getPartnerAvailableCitiesForCustomization(shippingAppId, countries);
      if (response instanceof ErrorModel || !response.cities) {
        Vue.$toast.error('Failed to load Cities For Selected Countries');
        return;
      }
      partnerCustomizationTempCities.options = [];
      const { cities } = response;
      if (cities && cities.length > 0) {
        cities.forEach((city: PartnerAvailableCitiesModel) => {
          partnerCustomizationTempCities.options.push({
            label: `${city.name.en} - ${city.name.ar}`,
            value: city.id,
            country_id: city.country_id,
          });
        });
        partnerCustomizationCities.options = [...partnerCustomizationTempCities.options];

        isLoadingCustomizationCities.value = true;
        const preSelectedCities: {
          label: string;
          value: number | null;
          country_id: number | null;
        }[] = [];

        preSelectedCustomizationCities.options.forEach((preSelectedCity) => {
          const city = partnerCustomizationCities.options.find(
            (cityOption) => cityOption.value === preSelectedCity.value,
          );
          if (city) preSelectedCities.push(city);
        });
        shippingOptionsFormState.locations.cities = [...preSelectedCities];
        isLoadingCustomizationCities.value = false;
      }
    }
  };

  watch(
    () => shippingOptionsFormState.locations.countries,
    (countries) => {
      if (countries && countries?.length !== 0) {
        getCitiesForSelectedCountries();
      } else {
        partnerCustomizationCities.options = [];
      }
    },
    { deep: true },
  );

  watch(
    () => shippingOptionsFormState.activationURL,
    (activationURL) => {
      if (!activationURL && customizationList.items.length === 0) {
        removeStepsCompleted([
          RoutingRouteEnum.CreateShippingApplication_WebhookManagement,
          RoutingRouteEnum.CreateShippingApplication_Publish,
        ]);
      }
    },
    { deep: true },
  );

  watch(
    () => customizationList.items,
    (customizations) => {
      if (customizations?.length === 0 && !shippingOptionsFormState.activationURL) {
        removeStepsCompleted([
          RoutingRouteEnum.CreateShippingApplication_WebhookManagement,
          RoutingRouteEnum.CreateShippingApplication_Publish,
        ]);
      }
    },
    { deep: true },
  );

  const loadSavedCustomizations = async () => {
    loadingCustomizationList.value = true;
    if (shippingAppId) {
      const customizationsResponse = await getPartnerCustomizations(shippingAppId);
      if (customizationsResponse instanceof ErrorModel || !customizationsResponse.customizations) {
        Vue.$toast.error('Failed to load Customizations');
        return;
      }
      customizationList.items = [];
      customizationList.items = customizationsResponse.customizations;
    }
    loadingCustomizationList.value = false;
  };

  onBeforeMount(async () => {
    if (shippingAppId) {
      const response = await getPartnerAvailableCountriesForCustomization(shippingAppId);
      if (response instanceof ErrorModel || !response.countries) {
        Vue.$toast.error('Failed to load Countries For Customizations');
        return;
      }
      partnerCustomizationCountries.options = [];
      const { countries } = response;
      isLoadingCustomizationCountries.value = true;
      if (countries && countries.length > 0) {
        countries.forEach((country: PartnerAvailableCountriesModel) => {
          partnerCustomizationCountries.options.push({
            label: `${country.name.en} - ${country.name.ar}`,
            value: country.id,
          });
        });
      } else Vue.$toast.error('No Country is available For Customizations');
      isLoadingCustomizationCountries.value = false;
      // customizations
      loadSavedCustomizations();
    }
  });

  return {
    v$,
    shippingAppId,
    customizationList,
    shippingOptionsFormState,
    partnerCustomizationCountries,
    partnerCustomizationCities,
    currencies,
    weightUnits,
    selectedMechanism,
    showAddCustomizationMsg,
    showAddCustomizationForm,
    SelectedPriceEnum,
    PricingTypesEnum,
    ActivationAndPricingMechanismEnum,
    selectedCODPricingType,
    selectedFixedShippingPricingType,
    showMessageForCustomizationNameArEnField,
    loadingCustomizationList,
    loadingDeleteCustomization,
    showDeleteCustomizationModal,
    loadingChangePricingMechanism,
    loadCustomizationDataInForm,
    showChangePricingMechanismModal,
    isAddNewCustomizationBtnDisable,
    isSaveBtnDisable,
    isSaveCustomizationBtnDisable,
    isUrlInvalid,
    CustomizationOptionLabelsEnum,
    ShippingOptionDetailsInputPropertiesEnum,
    isLoadingCustomizationCountries,
    isLoadingCustomizationCities,
    toggleChangePricingMechanismModal,
    onChangePricingMechanismConfirmationClicked,
    onEditCustomizationClick,
    onDeleteCustomizationClick,
    onDeleteSingleCustomizationConfirmationClicked,
    toggleDeleteCustomizationModal,
    customNameForLocations,
    onSelectedCountryRemove,
    toggleAddCustomizationForm,
    onPricingMechanismChange,
    onCODPricingTypeRadioClick,
    onShippingPricingTypeRadioClick,
    toggleAddCustomizationMsg,
    onShippingFixedPricingRadioClick,
    onInputDataChange,
    onCheckBoxValueChange,
    onResetCustomizationForm,
    onSaveCustomization,
  };
};

export default useShippingOptionDetails;
