import { computed, reactive, ref } from '@vue/composition-api';
import useVuelidate from '@vuelidate/core';
import { required, email, helpers, minLength, maxLength } from '@vuelidate/validators';
import { COMPANY_TYPES } from '@/domains/shipping/helper/dropdownInfo';
import { SHIPPING_SERVICES } from '@/domains/shipping/helper/checkBoxesInfo';
import { ShippingAppGeneralDataInterface } from '@/store/modules/shipping/applicationState.type';
import {
  CreateShippingAppSelectPropertyEnum,
  CreateShippingAppInputPropertiesEnum,
} from '@/domains/shipping/types/create-app/createApp.enum';
import { isValidURL } from '@/helpers/regex/URLs';
import { PHONE_NUMBER } from '@/helpers/regex/numbers';
import {
  ARABIC_TEXT_WITH_SPECIAL_CHARS,
  ENGLISH_TEXT_WITH_SPECIAL_CHARS,
  ARABIC_TEXT_WITH_NEW_LINE_AND_SPECIAL_CHARS,
  ENGLISH_TEXT_WITH_NEW_LINE_AND_SPECIAL_CHARS,
} from '@/helpers/regex/text';
import { createNamespacedHelpers } from 'vuex-composition-helpers';
import ShippingApplicationModel from '@/api/models/ShippingApplicationModel';

const { useGetters } = createNamespacedHelpers('shipping'); // module name

const useCompanyInfo = () => {
  let shippingAppData: ShippingApplicationModel | null = null;
  let tempServicesArray: string[] = [];
  let appId = null;
  const LOGO_MAX_WIDTH = 250;
  const LOGO_MAX_HEIGHT = 250;
  const logoFileNameFromGlobal = ref('');
  const isLogoValid = ref(true);

  const createShippingAppFormState = reactive<ShippingAppGeneralDataInterface>({
    appCompanyNameArabic: '',
    appCompanyNameEnglish: '',
    companyURL: '',
    technicalSupportEmail: '',
    mobileNumber: '',
    typeOfCompany: {
      label: COMPANY_TYPES[0].label,
      value: COMPANY_TYPES[0].value,
    },
    aboutCompanyEnglish: '',
    aboutCompanyArabic: '',
    companyLogo: '',
    services: '',
  });

  const { inProgressFullApp } = useGetters(['inProgressFullApp']);

  if (inProgressFullApp.value) {
    const {
      payload: { app },
    } = inProgressFullApp.value;
    appId = app.app_id;
    shippingAppData = app;
    tempServicesArray = JSON.parse(JSON.stringify(app?.services)) ?? '';
    logoFileNameFromGlobal.value = app.icon instanceof File ? URL.createObjectURL(app.icon) : app.icon;

    createShippingAppFormState.appCompanyNameArabic = shippingAppData?.name_ar ?? '';
    createShippingAppFormState.appCompanyNameEnglish = shippingAppData?.name_en ?? '';
    createShippingAppFormState.companyURL = shippingAppData?.url ?? '';
    createShippingAppFormState.technicalSupportEmail = shippingAppData?.email ?? '';
    createShippingAppFormState.mobileNumber = shippingAppData?.mobile ?? '';
    createShippingAppFormState.aboutCompanyEnglish = shippingAppData?.description_en ?? '';
    createShippingAppFormState.aboutCompanyArabic = shippingAppData?.description_ar ?? '';
    createShippingAppFormState.companyLogo = shippingAppData?.icon ?? '';
    createShippingAppFormState.services = shippingAppData?.services?.toString() ?? '';
    if (
      shippingAppData?.shipping_type != undefined &&
      COMPANY_TYPES.findIndex((x) => x.value == `${shippingAppData?.shipping_type}`) != -1
    ) {
      createShippingAppFormState.typeOfCompany = {
        label: COMPANY_TYPES[COMPANY_TYPES.findIndex((x) => x.value == `${shippingAppData?.shipping_type}`)].label,
        value: COMPANY_TYPES[COMPANY_TYPES.findIndex((x) => x.value == `${shippingAppData?.shipping_type}`)].value,
      };
    } else {
      createShippingAppFormState.typeOfCompany = {
        label: COMPANY_TYPES[0].label,
        value: COMPANY_TYPES[0].value,
      };
    }
  }

  const rules = {
    appCompanyNameArabic: {
      required,
      appCompanyNameArabic: helpers.withMessage(
        'Company name is required in both English and Arabic',
        (value: string) => {
          return ARABIC_TEXT_WITH_SPECIAL_CHARS.test(value);
        },
      ),
    },
    appCompanyNameEnglish: {
      required,
      appCompanyNameEnglish: helpers.withMessage(
        'Company name is required in both English and Arabic',
        (value: string) => {
          return ENGLISH_TEXT_WITH_SPECIAL_CHARS.test(value);
        },
      ),
    },
    companyURL: {
      required,
      companyURL: helpers.withMessage('Please enter a valid URL of Company.', (value: string) => {
        return isValidURL(value);
      }),
    },
    aboutCompanyArabic: {
      required,
      maxLength: maxLength(300),
      aboutCompanyArabic: helpers.withMessage(
        'Company description is required in both English and Arabic',
        (value: string) => {
          return ARABIC_TEXT_WITH_NEW_LINE_AND_SPECIAL_CHARS.test(value);
        },
      ),
    },
    aboutCompanyEnglish: {
      required,
      maxLength: maxLength(300),
      aboutCompanyEnglish: helpers.withMessage(
        'Company description is required in both English and Arabic',
        (value: string) => {
          return ENGLISH_TEXT_WITH_NEW_LINE_AND_SPECIAL_CHARS.test(value);
        },
      ),
    },
    typeOfCompany: {
      label: {
        required,
      },
      value: {
        required,
      },
    },
    companyLogo: {
      required,
    },
    mobileNumber: {
      required,
      mobileNumber: helpers.withMessage('Please enter a valid Mobile number', required),
      minLength: minLength(8),
      maxLength: maxLength(14),
      numberValue: (value: string) => {
        return PHONE_NUMBER.test(value);
      },
    },
    technicalSupportEmail: {
      required,
      email,
      technicalSupportEmail: helpers.withMessage('Please enter a valid email for Technical Support.', required),
    },
    services: {},
  };

  const companyTypes = reactive({
    options: COMPANY_TYPES,
  });

  const companyServices = reactive({
    options: SHIPPING_SERVICES,
  });

  const v$ = useVuelidate(rules, createShippingAppFormState);

  const onInputDataChange = (
    changedValue: Record<string, string>,
    dataProperty: CreateShippingAppInputPropertiesEnum,
  ) => {
    v$.value[dataProperty].$touch();
    createShippingAppFormState[dataProperty] = changedValue.value;
  };

  const onSelectionDataChange = (
    changedValue: Record<string, string>,
    property: CreateShippingAppSelectPropertyEnum,
  ) => {
    v$.value[property].$touch();
    const selectedValue = JSON.parse(JSON.stringify(changedValue));
    createShippingAppFormState[property].label = selectedValue.label;
    createShippingAppFormState[property].value = selectedValue.value;
  };

  const isCheckboxChecked = (label: string) => {
    return tempServicesArray?.includes(label) ? true : false;
  };

  const onCheckBoxValueChange = (item: string) => {
    v$.value.services.$touch();
    if (tempServicesArray?.indexOf(item) === -1) tempServicesArray?.push(item);
    else tempServicesArray?.splice(tempServicesArray?.indexOf(item), 1);
    createShippingAppFormState.services = tempServicesArray?.toString();
  };

  const onLogoSelection = (fileInputEvent: Event) => {
    const input = fileInputEvent.target as HTMLInputElement;
    if (!input.files?.length) {
      return;
    }
    const appLogo = new Image();
    appLogo.src = URL.createObjectURL(input.files[0]);

    appLogo.onerror = () => {
      isLogoValid.value = false;
      return;
    };

    appLogo.onload = () => {
      if (appLogo.width !== appLogo.height || appLogo.width > LOGO_MAX_WIDTH || appLogo.height > LOGO_MAX_HEIGHT) {
        isLogoValid.value = false;
        return;
      }
      if (input.files) {
        createShippingAppFormState.companyLogo = input.files[0];
        logoFileNameFromGlobal.value = URL.createObjectURL(input.files[0]);
        isLogoValid.value = true;
      }
    };
  };

  const onRemoveLogoClicked = () => {
    createShippingAppFormState.companyLogo = null;
    logoFileNameFromGlobal.value = '';
  };

  const onCancelClicked = () => {
    createShippingAppFormState.appCompanyNameEnglish = '';
    createShippingAppFormState.appCompanyNameArabic = '';
    createShippingAppFormState.companyURL = '';
    createShippingAppFormState.aboutCompanyEnglish = '';
    createShippingAppFormState.aboutCompanyArabic = '';
    createShippingAppFormState.companyLogo = '';
    createShippingAppFormState.mobileNumber = '';
    createShippingAppFormState.technicalSupportEmail = '';
    createShippingAppFormState.typeOfCompany.label = '';
    createShippingAppFormState.typeOfCompany.value = '';
    createShippingAppFormState.services = '';
  };

  const showMessageForCompanyNameArEnField = computed(() => {
    return (
      (v$.value.appCompanyNameArabic.$dirty || v$.value.appCompanyNameEnglish.$dirty) &&
      (v$.value.appCompanyNameArabic.$invalid || v$.value.appCompanyNameEnglish.$invalid)
    );
  });

  const showMessageForTechnicalSupportEmailField = computed(() => {
    return v$.value.technicalSupportEmail.$dirty && v$.value.technicalSupportEmail.$invalid;
  });

  const showMessageForMobileNumberField = computed(() => {
    return v$.value.mobileNumber.$dirty && v$.value.mobileNumber.$invalid;
  });

  const showMessageForTypeOfCompanyField = computed(() => {
    return v$.value.typeOfCompany.$dirty && v$.value.typeOfCompany.$invalid;
  });

  const showMessageForAboutArEnField = computed(() => {
    return (
      (v$.value.aboutCompanyEnglish.$dirty || v$.value.aboutCompanyArabic.$dirty) &&
      (v$.value.aboutCompanyArabic.$invalid || v$.value.aboutCompanyEnglish.$invalid)
    );
  });

  return {
    v$,
    appId,
    createShippingAppFormState,
    companyTypes,
    companyServices,
    isLogoValid,
    logoFileNameFromGlobal,
    showMessageForCompanyNameArEnField,
    showMessageForTechnicalSupportEmailField,
    showMessageForMobileNumberField,
    showMessageForTypeOfCompanyField,
    showMessageForAboutArEnField,
    CreateShippingAppSelectPropertyEnum,
    CreateShippingAppInputPropertiesEnum,
    isCheckboxChecked,
    onCheckBoxValueChange,
    onInputDataChange,
    onSelectionDataChange,
    onLogoSelection,
    onRemoveLogoClicked,
    onCancelClicked,
  };
};

export default useCompanyInfo;
