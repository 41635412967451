export type DevelopmentStore = {
  id: number;
  name: string;
  email: string;
  link: string;
  is_app_installed: number;
};

export type DevelopmentStoresList = {
  list: DevelopmentStore[];
};

export type ResponseErrorsList = {
  list: string[];
};

export interface DevelopmentStoresListParamsInterface {
  app_id?: number;
}

export interface changePasswordData {
  password: string;
  passwordConfirmation: string;
}

export interface createStoreDataInterface {
  name: string;
  password: string;
  passwordConfirmation: string;
}

export enum StoreFieldPropertyEnum {
  Name = 'name',
  Password = 'password',
  PasswordConfirmation = 'passwordConfirmation',
}

export enum ChangePasswordFieldPropertyEnum {
  Password = 'password',
  PasswordConfirmation = 'passwordConfirmation',
}

export enum ValidationErrorsEnum {
  Password = 'The password is required & it must be at least 8 characters',
  PasswordConfirmation = 'Password confirmation is required & it must match the password field',
  Name = 'Store Name is required & it must be at least 3 characters',
}

export enum AppInstallationStatus {
  Installed = 1,
}
