import httpClient from '@/api/httpClient';
import ErrorModel from '@/api/models/ErrorModel';
import CreateShippingApplicationResponseModel from '../../../models/CreateShippingApplicationResponseModel';

const ENDPOINT_URL = '/market/shipping/pricing-model';
export const changePricingMechanism = (
  app_id: number,
): Promise<CreateShippingApplicationResponseModel | ErrorModel> => {
  return httpClient
    .put(`${ENDPOINT_URL}/${app_id}`)
    .then((response) => new CreateShippingApplicationResponseModel(response))
    .catch((error) => error);
};
