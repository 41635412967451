import httpClient from '@/api/httpClient';
import DevelopmentStoresListResponseModel from '@/domains/development-store/models/DevelopmentStoresListResponseModel';
import ErrorModel from '@/api/models/ErrorModel';
import BaseModel from '@/api/models/BaseModel';
import {
  DevelopmentStoresListParamsInterface,
  changePasswordData,
} from '@/domains/development-store/types/developmentStore.type';

const ENDPOINT_URL = '/market/dev-stores';

export const getDevelopmentStores = (appId?: number): Promise<DevelopmentStoresListResponseModel | ErrorModel> => {
  const endpointParams: DevelopmentStoresListParamsInterface = {};
  if (appId) {
    endpointParams.app_id = appId;
  }
  return httpClient
    .get(ENDPOINT_URL, { params: endpointParams })
    .then((response) => new DevelopmentStoresListResponseModel(response))
    .catch((error) => error);
};

export const changeStorePassword = (storeId: number, data: changePasswordData): Promise<BaseModel | ErrorModel> => {
  return httpClient
    .put(`${ENDPOINT_URL}/${storeId}`, {
      password: data.password,
      password_confirmation: data.passwordConfirmation,
    })
    .then((response) => new BaseModel(response))
    .catch((error) => error);
};

export const installAppOnDevelopmentStore = (storeId: number, appId: number): Promise<BaseModel | ErrorModel> => {
  return httpClient
    .post(`${ENDPOINT_URL}/${storeId}/install-app`, {
      app_id: appId,
    })
    .then((response) => new BaseModel(response))
    .catch((error) => error);
};
