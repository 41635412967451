import httpClient from '@/api/httpClient';
import ErrorModel from '@/api/models/ErrorModel';
import { ShippingAppDetailsInfoType } from '../../../types/create-app/createApp.enum';
import CreateShippingApplicationResponseModel from '../../../models/CreateShippingApplicationResponseModel';

const ENDPOINT_URL = '/market/app/create-shippings';

export const setApplicationDetailsToApi = (
  applicationDetailsInfo: ShippingAppDetailsInfoType,
  app_id: number,
): Promise<CreateShippingApplicationResponseModel | ErrorModel> => {
  const appData = new FormData();
  appData.append('step', '3'); // static
  appData.append('app_id', `${app_id}`);
  appData.append('payment_due_en', applicationDetailsInfo.payment_due_en);
  appData.append('payment_due_ar', applicationDetailsInfo.payment_due_ar);
  appData.append('locations_from', applicationDetailsInfo.locations_from.toString());
  appData.append('locations_to', applicationDetailsInfo.locations_to.toString());
  appData.append('times_to_pickup_en', applicationDetailsInfo.times_to_deliver_en);
  appData.append('times_to_pickup_ar', applicationDetailsInfo.times_to_deliver_ar);
  appData.append('times_to_deliver_en', applicationDetailsInfo.times_to_pickup_en);
  appData.append('times_to_deliver_ar', applicationDetailsInfo.times_to_pickup_ar);
  appData.append('developer_name_en', applicationDetailsInfo.developer_name_en);
  appData.append('developer_name_ar', applicationDetailsInfo.developer_name_ar);
  appData.append('short_description_en', applicationDetailsInfo.short_description_en);
  appData.append('short_description_ar', applicationDetailsInfo.short_description_ar);
  appData.append('weight_details_en', applicationDetailsInfo.weight_details_en);
  appData.append('weight_details_ar', applicationDetailsInfo.weight_details_ar);
  if (applicationDetailsInfo.screen_shots_ar.length) {
    applicationDetailsInfo.screen_shots_ar.map((screenshot, index) => {
      appData.append(`screen_shots_ar[${index}]`, screenshot);
    });
  } else appData.append(`screen_shots_ar`, '');
  if (applicationDetailsInfo.screen_shots_en.length) {
    applicationDetailsInfo.screen_shots_en.map((screenshot, index) => {
      appData.append(`screen_shots_en[${index}]`, screenshot);
    });
  } else appData.append(`screen_shots_en`, '');

  return httpClient
    .post(`${ENDPOINT_URL}`, appData)
    .then((response) => new CreateShippingApplicationResponseModel(response))
    .catch((error) => error);
};
