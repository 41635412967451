import httpClient from '@/api/httpClient';
import ErrorModel from '@/api/models/ErrorModel';
import CreateShippingApplicationResponseModel from '../../../models/CreateShippingApplicationResponseModel';
import { GeneralSettingsInfoType } from '../../../types/create-app/createApp.enum';

const ENDPOINT_URL = '/market/app/create-shippings';

export const setGeneralSettingsDataToApi = (
  generalSettingsInfo: GeneralSettingsInfoType,
): Promise<CreateShippingApplicationResponseModel | ErrorModel> => {
  return httpClient
    .post(`${ENDPOINT_URL}`, generalSettingsInfo)
    .then((response) => new CreateShippingApplicationResponseModel(response))
    .catch((error) => error);
};
