class CreateShippingApplicationInformationModel {
  public app_id: number;
  public step: number;
  public name_en: string;
  public name_ar: string;
  public url: string;
  public description_en: string;
  public description_ar: string;
  public icon: File | string | null;
  public mobile: string;
  public email: string;
  public shipping_type: string;
  public services: string;

  constructor(arg: any) {
    this.app_id = arg.app_id;
    this.step = arg.step;
    this.name_en = arg.name_en;
    this.name_ar = arg.name_ar;
    this.url = arg.url;
    this.description_en = arg.description_en;
    this.description_ar = arg.description_ar;
    this.icon = arg.icon;
    this.mobile = arg.mobile;
    this.email = arg.email;
    this.shipping_type = arg.shipping_type;
    this.services = arg.services;
  }
}

export default CreateShippingApplicationInformationModel;
