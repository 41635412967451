import httpClient from '@/api/httpClient';
import PartnerSingleCustomizationResponseModel from '@/domains/shipping/models/PartnerSingleCustomizationResponseModel';

const ENDPOINT_URL = '/partners/shipping/courier';

export const getPartnerSingleCustomizationDetails = (app_id: number, customizationId: number) => {
  return httpClient
    .get(`${ENDPOINT_URL}/${app_id}/customizations/${customizationId}`)
    .then((response) => new PartnerSingleCustomizationResponseModel(response))
    .catch((error) => error);
};
