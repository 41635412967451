import Vue from 'vue';
import { defineComponent, reactive, ref, watch } from '@vue/composition-api';
import { ZidInput, ZidCheckbox, ZidIcon, ZidButton, ZidTextarea, ZidLoader } from '@zidsa/ui';
import BreadCrumb from '../../../components/breadcrumb/BreadCrumb.vue';
import { GeneralSettingsInputPropertiesEnum } from '../../../types/create-app/createApp.enum';
import BaseText from '@/components/text/BaseText.vue';
import useVuelidate from '@vuelidate/core';
import { required, helpers } from '@vuelidate/validators';
import { isValidURLWithLocalhost } from '@/helpers/regex/URLs';
import router from '@/router/index';
import { RoutingRouteEnum } from '@/router/routes.enum';
import { createNamespacedHelpers } from 'vuex-composition-helpers';
import ErrorModel from '@/api/models/ErrorModel';
import { setGeneralSettingsDataToApi } from '../../../api/steps/general-settings/generalSettings';
import InstallAppOnDevelopmentStore from '@/domains/development-store/components/install-app-on-development-store/InstallAppOnDevelopmentStore.vue';

const { useGetters, useActions } = createNamespacedHelpers('shipping'); // module name

export default defineComponent({
  components: {
    ZidInput,
    BaseText,
    ZidCheckbox,
    ZidIcon,
    ZidButton,
    ZidTextarea,
    BreadCrumb,
    ZidLoader,
    InstallAppOnDevelopmentStore,
  },
  setup() {
    const disabledBreadCrumbList = reactive([
      RoutingRouteEnum.CreateShippingApplication_AboutCompany,
      RoutingRouteEnum.CreateShippingApplication_ShippingOptionDetails,
      RoutingRouteEnum.CreateShippingApplication_WebhookManagement,
      RoutingRouteEnum.CreateShippingApplication_Publish,
    ]);

    let appId: number | null = null;
    let clientID = '';
    let clientSecret = '';
    let callbackUrl = '';
    let redirectUrl = '';
    const callbackURLDocsLink = 'https://docs.zid.sa/docs/authorization-migration/2cgihlrgqoz36-authorization';
    const redirectURLDocsLink = 'https://docs.zid.sa/docs/authorization-migration/2cgihlrgqoz36-authorization';
    const mdAppLink = ref('');
    const { inProgressFullApp } = useGetters(['inProgressFullApp']);
    const { addStepCompleted, setInProgressFullApplication } = useActions([
      'addStepCompleted',
      'setInProgressFullApplication',
    ]);

    if (inProgressFullApp.value) {
      const {
        payload: { app, mdLink },
      } = inProgressFullApp.value;
      appId = app.app_id;
      mdAppLink.value = mdLink;
      const { callback_url, redirect_url } = app;
      const { client_id, client_secret } = app.oauth_keys;

      clientID = `${client_id}`;
      clientSecret = client_secret;
      callbackUrl = callback_url;
      redirectUrl = redirect_url;
    }

    const createShippingAppGeneralSettings = reactive<Record<string, string | number>>({
      clientID: clientID ?? '',
      clientSecret: clientSecret ?? '',
      redirectUrl: redirectUrl ?? '',
      callbackUrl: callbackUrl ?? '',
    });

    const rules = {
      clientID: {
        required,
      },
      clientSecret: {
        required,
      },
      redirectUrl: {
        required,
        redirectUrl: helpers.withMessage('Please enter a valid URL.', (value: string) => {
          return isValidURLWithLocalhost(value);
        }),
      },
      callbackUrl: {
        required,
        callbackUrl: helpers.withMessage('Please enter a valid URL.', (value: string) => {
          return isValidURLWithLocalhost(value);
        }),
      },
    };

    const v$ = useVuelidate(rules, createShippingAppGeneralSettings);

    const onInputDataChange = (
      changedValue: Record<string, string>,
      dataProperty: GeneralSettingsInputPropertiesEnum,
    ) => {
      v$.value[dataProperty].$touch();
      createShippingAppGeneralSettings[dataProperty] = changedValue.value;
    };

    const onBackClicked = () => {
      router.push({ name: RoutingRouteEnum.CreateShippingApplication_CompanyInfo }).catch(() => {
        //
      });
    };

    const onSubmit = async () => {
      const shippingAppGeneralInfo = {
        step: 2, // static
        app_id: appId,
        callback_url: `${createShippingAppGeneralSettings.callbackUrl}`,
        redirect_url: `${createShippingAppGeneralSettings.redirectUrl}`,
      };
      const response = await setGeneralSettingsDataToApi(shippingAppGeneralInfo);
      if (response instanceof ErrorModel || !response.payload) {
        Vue.$toast.error('Failed to update application details');
        return;
      }
      const newFullApp = {
        payload: { ...response.payload },
      };
      setInProgressFullApplication(newFullApp);
      addStepCompleted(RoutingRouteEnum.CreateShippingApplication_AboutCompany);
      router
        .push({
          name: RoutingRouteEnum.CreateShippingApplication_AboutCompany,
        })
        .catch(() => {
          //
        });
      Vue.$toast.success('Application Details are Updated Successfully');
    };

    watch(
      () => inProgressFullApp,
      (inPorgressFullAppValue) => {
        if (inPorgressFullAppValue.value) {
          const {
            payload: { app, mdLink },
          } = inPorgressFullAppValue.value;
          appId = app.app_id;
          const { callback_url, redirect_url } = app;
          const { client_id, client_secret } = app.oauth_keys;
          createShippingAppGeneralSettings.clientID = `${client_id}`;
          createShippingAppGeneralSettings.clientSecret = `${client_secret}`;
          createShippingAppGeneralSettings.redirectURL = `${redirect_url}`;
          createShippingAppGeneralSettings.callbackUrl = `${callback_url}`;
          mdAppLink.value = mdLink;
        }
      },
      { deep: true },
    );

    return {
      v$,
      callbackURLDocsLink,
      redirectURLDocsLink,
      disabledBreadCrumbList,
      GeneralSettingsInputPropertiesEnum,
      mdAppLink,
      appId,
      onInputDataChange,
      onBackClicked,
      onSubmit,
    };
  },
});
