import Vue from 'vue';
import { defineComponent, reactive } from '@vue/composition-api';
import BaseText from '@/components/text/BaseText.vue';
import {
  ZidButton,
  ZidIcon,
  ZidInput,
  ZidTextarea,
  ZidSelect,
  ZidSelectHeader,
  ZidSelectBody,
  ZidSelectOption,
  ZidFileSelector,
  ZidCheckbox,
} from '@zidsa/ui';
import BreadCrumb from '@/domains/shipping/components/breadcrumb/BreadCrumb.vue';
import useCompanyInfo from '@/domains/shipping/helper/hooks/company-info/useComapnyInfo';
import { createNamespacedHelpers } from 'vuex-composition-helpers';
import CreateShippingApplicationInformationModel from '@/domains/shipping/models/CreateShippingApplicationInformationModel';
import { createShippingApplication } from '@/domains/shipping/api/createShippingApplication';
import TabMultiLanguage from '@/components/tab-multi-language/TabMultiLanguage.vue';
import router from '@/router/index';
import { RoutingRouteEnum } from '@/router/routes.enum';
import ErrorModel from '@/api/models/ErrorModel';

const { useActions } = createNamespacedHelpers('shipping');

export default defineComponent({
  components: {
    ZidButton,
    BaseText,
    ZidIcon,
    ZidInput,
    ZidTextarea,
    ZidSelect,
    ZidSelectHeader,
    ZidSelectBody,
    ZidSelectOption,
    ZidFileSelector,
    TabMultiLanguage,
    ZidCheckbox,
    BreadCrumb,
  },
  setup() {
    const {
      appId,
      createShippingAppFormState,
      v$,
      companyTypes,
      companyServices,
      isLogoValid,
      logoFileNameFromGlobal,
      showMessageForCompanyNameArEnField,
      showMessageForAboutArEnField,
      showMessageForMobileNumberField,
      showMessageForTypeOfCompanyField,
      showMessageForTechnicalSupportEmailField,
      CreateShippingAppSelectPropertyEnum,
      CreateShippingAppInputPropertiesEnum,
      isCheckboxChecked,
      onCheckBoxValueChange,
      onInputDataChange,
      onSelectionDataChange,
      onLogoSelection,
      onCancelClicked,
      onRemoveLogoClicked,
    } = useCompanyInfo();

    const disabledBreadCrumbList = reactive([
      RoutingRouteEnum.CreateShippingApplication_GeneralSettings,
      RoutingRouteEnum.CreateShippingApplication_AboutCompany,
      RoutingRouteEnum.CreateShippingApplication_ShippingOptionDetails,
      RoutingRouteEnum.CreateShippingApplication_WebhookManagement,
      RoutingRouteEnum.CreateShippingApplication_Publish,
    ]);

    const { addStepCompleted, setShippingAppGeneralData, setInProgressFullApplication } = useActions([
      'addStepCompleted',
      'setShippingAppGeneralData',
      'setInProgressFullApplication',
    ]);

    const onSubmit = async () => {
      const shippingAppDataState = {
        appCompanyNameEnglish: v$.value.appCompanyNameEnglish.$model,
        appCompanyNameArabic: v$.value.appCompanyNameArabic.$model,
        companyURL: v$.value.companyURL.$model,
        aboutCompanyEnglish: v$.value.aboutCompanyEnglish.$model,
        aboutCompanyArabic: v$.value.aboutCompanyArabic.$model,
        companyLogo: v$.value.companyLogo.$model,
        mobileNumber: v$.value.mobileNumber.$model,
        technicalSupportEmail: v$.value.technicalSupportEmail.$model,
        typeOfCompany: v$.value.typeOfCompany.$model,
        services: v$.value.services.$model,
      };

      const createShippingApplicationInformation = new CreateShippingApplicationInformationModel({
        app_id: appId,
        name_en: createShippingAppFormState.appCompanyNameEnglish,
        name_ar: createShippingAppFormState.appCompanyNameArabic,
        url: createShippingAppFormState.companyURL,
        icon: createShippingAppFormState.companyLogo instanceof File ? createShippingAppFormState.companyLogo : null,
        mobile: createShippingAppFormState.mobileNumber,
        email: createShippingAppFormState.technicalSupportEmail,
        shipping_type: createShippingAppFormState.typeOfCompany.value,
        description_en: createShippingAppFormState.aboutCompanyEnglish,
        description_ar: createShippingAppFormState.aboutCompanyArabic,
        services: createShippingAppFormState.services,
      });

      const response = await createShippingApplication(createShippingApplicationInformation);
      if (response instanceof ErrorModel || !response.payload) {
        Vue.$toast.error('Failed to update application details');
        return;
      }
      const newFullApp = {
        payload: { ...response.payload },
      };
      setInProgressFullApplication(newFullApp);
      setShippingAppGeneralData(shippingAppDataState);
      addStepCompleted(RoutingRouteEnum.CreateShippingApplication_GeneralSettings);
      Vue.$toast.success('Application Details are Updated Successfully');
      router
        .push({
          name: RoutingRouteEnum.CreateShippingApplication_GeneralSettings,
        })
        .catch((err) => {
          //
        });
    };

    return {
      v$,
      appId,
      isLogoValid,
      companyTypes,
      companyServices,
      disabledBreadCrumbList,
      logoFileNameFromGlobal,
      showMessageForAboutArEnField,
      showMessageForMobileNumberField,
      showMessageForTypeOfCompanyField,
      showMessageForCompanyNameArEnField,
      CreateShippingAppSelectPropertyEnum,
      CreateShippingAppInputPropertiesEnum,
      showMessageForTechnicalSupportEmailField,
      isCheckboxChecked,
      onCheckBoxValueChange,
      onInputDataChange,
      onSelectionDataChange,
      onLogoSelection,
      onCancelClicked,
      onRemoveLogoClicked,
      onSubmit,
    };
  },
});
