import Vue from 'vue';
import { reactive, ref, onBeforeMount, computed, watch } from '@vue/composition-api';
import useVuelidate from '@vuelidate/core';
import { helpers, required } from '@vuelidate/validators';
import { isValidURL } from '@/helpers/regex/URLs';
import router from '@/router/index';
import { RoutingRouteEnum } from '@/router/routes.enum';
import { createNamespacedHelpers } from 'vuex-composition-helpers';
import {
  WebhookType,
  WebhookGroup,
  WebhookResponse,
  SubscribtionType,
  SubscribtionTypesEnum,
  WebhooksFromSaveBtnType,
  AvailableWebhooksOptionsType,
  WebhooksManagmentInputPropertiesEnum,
} from '@/domains/shipping/types/create-app/createApp.enum';
import {
  getAppWebhooksData,
  setWebhookGroupDataToApi,
  deleteWebhookGroup,
} from '@/domains/shipping/api/steps/webhook-management/webhookManagement';
import ErrorModel from '@/api/models/ErrorModel';

const { useGetters, useActions } = createNamespacedHelpers('shipping');

const useWebhooksManagement = () => {
  const { inProgressFullApp } = useGetters(['inProgressFullApp']);
  const { addStepCompleted, removeStepsCompleted } = useActions(['addStepCompleted', 'removeStepsCompleted']);

  const showAddWebhook = ref(false);
  const loadingExistingWebhooks = ref(false);
  const loadingAddOrEditWebhook = ref(false);
  const showDeleteWebhookModal = ref(false);
  const isDeletingWebhookGroup = ref(false);
  const selectedWebhookGroupID = ref('');
  const webhooksOptions: AvailableWebhooksOptionsType = reactive({
    options: {
      orders: [],
      subscription: [],
      products: [],
      sync: [],
    },
  });
  const webhooksFormSaveBtnState: WebhooksFromSaveBtnType = reactive({
    orders: false,
    subscription: false,
    products: false,
    sync: false,
  });
  let shippingAppId: number | null = null;
  let existingWebhookGroups: Record<string, WebhookResponse[]> | null = null;

  if (inProgressFullApp.value) {
    const {
      payload: { app },
    } = inProgressFullApp.value;
    shippingAppId = app.app_id;
  }

  const createShippingAppWebhooksState: SubscribtionType = reactive({
    orders: {
      id: -1,
      webhookSelection: [],
      targetURL: '',
      headers: '',
    },
    subscription: {
      id: -1,
      webhookSelection: [],
      targetURL: '',
      headers: '',
    },
    products: {
      id: -1,
      webhookSelection: [],
      targetURL: '',
      headers: '',
    },
    sync: {
      id: -1,
      webhookSelection: [],
      targetURL: '',
      headers: '',
    },
  });

  const webhookSubscribtionList: Record<string, WebhookGroup[]> = reactive({ subscirbtionList: [] });

  const isValidJsonString = (str: string) => {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  };

  const rules = {
    orders: {
      webhookSelection: {
        required,
        webhookSelection: helpers.withMessage('Please select application webhooks', required),
      },
      targetURL: {
        required,
        targetURL: helpers.withMessage('URL is not valid', (value: string) => {
          return isValidURL(value);
        }),
      },
      headers: {
        headers: helpers.withMessage(
          'JSON format is not valid',
          (value: string) => isValidJsonString(value) || value === '',
        ),
      },
    },
    subscription: {
      webhookSelection: {
        required,
        webhookSelection: helpers.withMessage('Please select application webhooks', required),
      },
      targetURL: {
        required,
        targetURL: helpers.withMessage('URL is not valid', (value: string) => {
          return isValidURL(value);
        }),
      },
      headers: {
        headers: helpers.withMessage(
          'JSON format is not valid',
          (value: string) => isValidJsonString(value) || value === '',
        ),
      },
    },
    products: {
      webhookSelection: {
        required,
        webhookSelection: helpers.withMessage('Please select application webhooks', required),
      },
      targetURL: {
        required,
        targetURL: helpers.withMessage('URL is not valid', (value: string) => {
          return isValidURL(value);
        }),
      },
      headers: {
        headers: helpers.withMessage(
          'JSON format is not valid',
          (value: string) => isValidJsonString(value) || value === '',
        ),
      },
    },
    sync: {
      webhookSelection: {
        required,
        webhookSelection: helpers.withMessage('Please select application webhooks', required),
      },
      targetURL: {
        required,
        targetURL: helpers.withMessage('URL is not valid', (value: string) => {
          return isValidURL(value);
        }),
      },
      headers: {
        headers: helpers.withMessage(
          'JSON format is not valid',
          (value: string) => isValidJsonString(value) || value === '',
        ),
      },
    },
  };

  const v$ = useVuelidate(rules, createShippingAppWebhooksState);

  const toggleAddwebhooksForm = () => (showAddWebhook.value = !showAddWebhook.value);
  const toggleDeleteWebhookModal = () => (showDeleteWebhookModal.value = !showDeleteWebhookModal.value);

  const onSaveBtnClicked = async () => {
    if (webhookSubscribtionList.subscirbtionList) {
      addStepCompleted(RoutingRouteEnum.CreateShippingApplication_Publish);
      router
        .push({
          name: RoutingRouteEnum.CreateShippingApplication_Publish,
        })
        .catch(() => {
          //
        });
      Vue.$toast.success('Application Details are Updated Successfully');
    } else {
      Vue.$toast.error('Please Create Some Webhooks');
    }
  };

  const onCancelClicked = () => {
    resetWebhooksFormData();
  };

  const onInputDataChange = (
    changedValue: Record<string, string>,
    dataProperty: WebhooksManagmentInputPropertiesEnum,
    formState: SubscribtionTypesEnum,
  ) => {
    v$.value[formState][dataProperty].$touch();
    createShippingAppWebhooksState[formState][dataProperty] = changedValue.value;
  };

  const resetWebhooksFormData = () => {
    Object.keys(createShippingAppWebhooksState).forEach((property) => {
      const dataProperty = property as keyof SubscribtionType;
      createShippingAppWebhooksState[dataProperty] = {
        id: -1,
        webhookSelection: [],
        targetURL: '',
        headers: '',
      };
    });
    selectedWebhookGroupID.value = '';
    v$.value.$reset();
  };

  const onWebhookGroupEdit = (webhookGroupID: string, dataProperty: SubscribtionTypesEnum) => {
    resetWebhooksFormData();
    selectedWebhookGroupID.value = webhookGroupID;
    if (!showAddWebhook.value) toggleAddwebhooksForm();
    const webhooks = webhookSubscribtionList.subscirbtionList?.find(
      (webhookGroup: WebhookGroup) => webhookGroup.group_id === selectedWebhookGroupID.value,
    )?.webhooks;
    if (webhooks?.length && createShippingAppWebhooksState[dataProperty]) {
      createShippingAppWebhooksState[dataProperty].webhookSelection = webhooks?.map((webhook) => webhook.name);
      createShippingAppWebhooksState[dataProperty].targetURL = webhooks[0].target_url;
      createShippingAppWebhooksState[dataProperty].headers = webhooks[0].headers ?? '{}';
    }
  };

  const onWebhookGroupDelete = async (webhookGroupID: string) => {
    selectedWebhookGroupID.value = webhookGroupID;
    showDeleteWebhookModal.value = true;
  };

  const onDeleteConfirmationClicked = async () => {
    if (!shippingAppId || !selectedWebhookGroupID) {
      return;
    }
    isDeletingWebhookGroup.value = true;
    const response = await deleteWebhookGroup(selectedWebhookGroupID.value, shippingAppId);
    toggleDeleteWebhookModal();
    isDeletingWebhookGroup.value = false;
    if (response instanceof ErrorModel || !response.deleted) {
      Vue.$toast.error('Failed to delete webhook group');
      return;
    }
    Vue.$toast.success('Webhook group has been deleted');
    loadWebhooksData();
  };

  const prepareWebhookGroupData = (dataProperty: SubscribtionTypesEnum) => {
    const webhooks: WebhookType[] = [];
    let webhookGroupID = null;
    let selectedWebhookGroup: WebhookGroup | null = null;
    if (selectedWebhookGroupID.value) {
      webhookGroupID = selectedWebhookGroupID.value;
      selectedWebhookGroup =
        webhookSubscribtionList.subscirbtionList?.find(
          (webhhook) => webhhook.group_id === selectedWebhookGroupID.value,
        ) || null;
    }
    createShippingAppWebhooksState[dataProperty].webhookSelection.map((selection) => {
      webhooks.push({
        id: selectedWebhookGroup?.webhooks.find((webhook) => webhook.name === selection)?.id || null,
        name: selection,
        target_url: createShippingAppWebhooksState[dataProperty].targetURL,
        headers: createShippingAppWebhooksState[dataProperty].headers
          ? JSON.parse(createShippingAppWebhooksState[dataProperty].headers)
          : null,
      });
    });

    const newWebhookGroup: WebhookGroup = {
      group_id: webhookGroupID,
      webhooks,
    };
    return newWebhookGroup;
  };

  const onSaveOrEditWebhookClicked = async (dataProperty: SubscribtionTypesEnum) => {
    if (shippingAppId) {
      webhooksFormSaveBtnState[dataProperty] = true;
      const newWebhookGroup = prepareWebhookGroupData(dataProperty);
      const response = await setWebhookGroupDataToApi(newWebhookGroup, shippingAppId);
      if (response instanceof ErrorModel || !response.webhooks) {
        webhooksFormSaveBtnState[dataProperty] = false;
        if (selectedWebhookGroupID.value !== '') Vue.$toast.error('Failed to update webhook group');
        else Vue.$toast.error('Failed to Add new webhook group');
        return;
      }
      if (selectedWebhookGroupID.value !== '') Vue.$toast.success('Updated webhook group successfully');
      else Vue.$toast.success('Added new webhook group successfully');
      loadWebhooksData();
    }
    webhooksFormSaveBtnState[dataProperty] = false;
    selectedWebhookGroupID.value = '';
    resetWebhooksFormData();
  };

  const updateCurrentSupscriptionList = () => {
    if (!existingWebhookGroups) return;
    webhookSubscribtionList.subscirbtionList = [];
    for (const [key, value] of Object.entries(existingWebhookGroups)) {
      webhookSubscribtionList.subscirbtionList.push({
        group_id: key,
        webhooks: value,
      });
    }
  };

  const loadWebhooksData = async () => {
    if (shippingAppId) {
      loadingExistingWebhooks.value = true;
      const response = await getAppWebhooksData(shippingAppId);
      if (response instanceof ErrorModel || !response.payload) {
        loadingExistingWebhooks.value = false;
        return;
      }
      existingWebhookGroups = response.payload.webhooks;
      webhooksOptions.options = response.payload.webhooksAvailable;
      updateCurrentSupscriptionList();
      loadingExistingWebhooks.value = false;
    } else {
      Vue.$toast.error('Failed to Find the App');
    }
  };

  const allWebhooksAreAdded = computed(() => {
    return Object.values(webhooksOptions.options).every((option) => option.length === 0);
  });

  watch(
    () => webhookSubscribtionList.subscirbtionList,
    () => {
      if (!Object.values(webhooksOptions.options).every((option) => option.length === 0)) {
        removeStepsCompleted([RoutingRouteEnum.CreateShippingApplication_Publish]);
      }
    },
    { deep: true },
  );

  onBeforeMount(async () => {
    loadWebhooksData();
  });

  return {
    v$,
    createShippingAppWebhooksState,
    SubscribtionTypesEnum,
    WebhooksManagmentInputPropertiesEnum,
    webhooksOptions,
    webhookSubscribtionList,
    showAddWebhook,
    loadingAddOrEditWebhook,
    loadingExistingWebhooks,
    showDeleteWebhookModal,
    isDeletingWebhookGroup,
    selectedWebhookGroupID,
    webhooksFormSaveBtnState,
    allWebhooksAreAdded,
    onInputDataChange,
    toggleAddwebhooksForm,
    onSaveBtnClicked,
    onCancelClicked,
    toggleDeleteWebhookModal,
    onSaveOrEditWebhookClicked,
    onWebhookGroupEdit,
    onWebhookGroupDelete,
    onDeleteConfirmationClicked,
  };
};

export default useWebhooksManagement;
