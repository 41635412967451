import httpClient from '@/api/httpClient';
import ErrorModel from '@/api/models/ErrorModel';
import CreateShippingApplicationResponseModel from '@/domains/shipping/models/CreateShippingApplicationResponseModel';

const ENDPOINT_URL = '/market/app/create-shippings';

export const setShippingOptionDetailsToApi = (
  app_id: number,
  contractLink: string,
): Promise<CreateShippingApplicationResponseModel | ErrorModel> => {
  const appData = new FormData();
  appData.append('step', '4'); // static
  appData.append('app_id', `${app_id}`);
  if (contractLink) {
    appData.append('contract_link', contractLink);
  }
  return httpClient
    .post(ENDPOINT_URL, appData)
    .then((response) => new CreateShippingApplicationResponseModel(response))
    .catch((error) => error);
};
