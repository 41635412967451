import httpClient from '@/api/httpClient';
import ErrorModel from '@/api/models/ErrorModel';
import PartnerAvailableCitiesResponseModel from '../models/PartnerAvailableCitiesResponseModel';

const ENDPOINT_URL = 'partners/shipping/couriers';

export const getPartnerAvailableCitiesForCustomization = (
  app_id: number,
  countries: (number | null)[],
): Promise<PartnerAvailableCitiesResponseModel | ErrorModel> => {
  return httpClient
    .post(`${ENDPOINT_URL}/${app_id}/available-customization-cities`, { countries: countries })
    .then((response) => new PartnerAvailableCitiesResponseModel(response))
    .catch((error) => error);
};
