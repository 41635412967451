import httpClient from '@/api/httpClient';
import ErrorModel from '@/api/models/ErrorModel';
import PartnerCustomizationResponseModel from '@/domains/shipping/models/PartnerCustomizationsResponseModel';

const ENDPOINT_URL = '/partners/shipping/courier';

export const getPartnerCustomizations = (app_id: number): Promise<PartnerCustomizationResponseModel | ErrorModel> => {
  return httpClient
    .get(`${ENDPOINT_URL}/${app_id}/customizations`)
    .then((response) => new PartnerCustomizationResponseModel(response))
    .catch((error) => error);
};
