import httpClient from '@/api/httpClient';
import ErrorModel from '@/api/models/ErrorModel';
import CreateShippingApplicationInformationModel from '../models/CreateShippingApplicationInformationModel';
import CreateShippingApplicationResponseModel from '../models/CreateShippingApplicationResponseModel';

const ENDPOINT_URL = '/market/app/create-shippings';

export const createShippingApplication = (
  shippingAppInfo: CreateShippingApplicationInformationModel,
): Promise<CreateShippingApplicationResponseModel | ErrorModel> => {
  const appData = new FormData();
  if (shippingAppInfo.app_id) appData.append('app_id', `${shippingAppInfo.app_id}`); // for edit case only
  appData.append('step', '1');
  appData.append('name_en', shippingAppInfo.name_en);
  appData.append('name_ar', shippingAppInfo.name_ar);
  appData.append('url', shippingAppInfo.url);
  appData.append('email', shippingAppInfo.email);
  appData.append('mobile', shippingAppInfo.mobile);
  appData.append('shipping_type', shippingAppInfo.shipping_type);
  appData.append('description_en', shippingAppInfo.description_en);
  appData.append('description_ar', shippingAppInfo.description_ar);
  appData.append('services', shippingAppInfo.services);
  if (shippingAppInfo.icon) appData.append('icon', shippingAppInfo.icon);
  return httpClient
    .post(ENDPOINT_URL, appData)
    .then((response) => new CreateShippingApplicationResponseModel(response))
    .catch((error) => error);
};
