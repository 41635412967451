import BaseModel from '@/api/models/BaseModel';
import PartnerAvailableCountriesModel from './PartnerAvailableCountriesModel';

class PartnerCountriesForCoverageResponseModel extends BaseModel {
  public countries: PartnerAvailableCountriesModel[];

  constructor(arg: any) {
    super(arg);
    this.countries = arg.countries;
  }
}

export default PartnerCountriesForCoverageResponseModel;
