import httpClient from '@/api/httpClient';
import ErrorModel from '@/api/models/ErrorModel';
import PartnerCountriesForCoverageResponseModel from '../models/PartnerCountriesForCoverageResponseModel';

const ENDPOINT_URL = 'partners/shipping/couriers/';

export const getPartnerCountriesForCoverage = (
  app_id: number,
): Promise<PartnerCountriesForCoverageResponseModel | ErrorModel> => {
  return httpClient
    .get(`${ENDPOINT_URL + app_id}/available-coverage-countries`)
    .then((response) => new PartnerCountriesForCoverageResponseModel(response))
    .catch((error) => error);
};
