import httpClient from '@/api/httpClient';
import ErrorModel from '@/api/models/ErrorModel';
import PartnerAvailableCitiesResponseModel from '../models/PartnerAvailableCitiesResponseModel';

const ENDPOINT_URL = 'partners/shipping/couriers/';

export const getPartnerAvailableCoverageCities = (
  app_id: number,
  cityType: string,
  countries: (number | null)[],
): Promise<PartnerAvailableCitiesResponseModel | ErrorModel> => {
  return httpClient
    .post(`${ENDPOINT_URL + app_id}/available-coverage-cities`, {
      cityType: cityType,
      countries: countries,
    })
    .then((response) => new PartnerAvailableCitiesResponseModel(response))
    .catch((error) => error);
};
