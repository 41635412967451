import BaseModel from '@/api/models/BaseModel';
import { DevelopmentStore } from '@/domains/development-store/types/developmentStore.type';

export default class DevelopmentStoresListResponseModel extends BaseModel {
  public payload: DevelopmentStore[];
  constructor(args: any) {
    super(args);
    this.payload = args.payload;
  }
}
