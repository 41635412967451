<template>
  <div class="px-4 py-6 my-8 mx-6 bg-white-pureWhite shadow-md">
    <zid-breadcrumb>
      <zid-breadcrumb-item
        v-for="(step, index) in shippingAppCreationSteps"
        :key="index"
        :route="step.route"
        :disabled="!stepsCompleted.includes(step.route) && disabledItemsList.includes(step.route)"
      >
        <base-text textStyle="h4">{{ step.text }}</base-text>
      </zid-breadcrumb-item>
    </zid-breadcrumb>
  </div>
</template>

<script>
import { defineComponent } from '@vue/composition-api';
import { RoutingRouteEnum } from '@/router/routes.enum';
import { ZidBreadcrumb, ZidBreadcrumbItem } from '@zidsa/ui';
import BaseText from '@/components/text/BaseText.vue';
import { createNamespacedHelpers } from 'vuex-composition-helpers';

const { useGetters } = createNamespacedHelpers('shipping'); // module name

export default defineComponent({
  props: {
    disabledItemsList: {
      type: Array,
      default: [],
    },
  },
  components: {
    ZidBreadcrumb,
    ZidBreadcrumbItem,
    BaseText,
  },
  setup() {
    const { stepsCompleted } = useGetters(['stepsCompleted']);

    const shippingAppCreationSteps = [
      {
        route: RoutingRouteEnum.CreateShippingApplication_CompanyInfo,
        text: 'Company Info',
      },
      {
        route: RoutingRouteEnum.CreateShippingApplication_GeneralSettings,
        text: 'General Settings',
      },
      {
        route: RoutingRouteEnum.CreateShippingApplication_AboutCompany,
        text: 'About Company',
      },
      {
        route: RoutingRouteEnum.CreateShippingApplication_ShippingOptionDetails,
        text: 'Shipping Option Details',
      },
      {
        route: RoutingRouteEnum.CreateShippingApplication_WebhookManagement,
        text: 'Webhook Management',
      },
      {
        route: RoutingRouteEnum.CreateShippingApplication_Publish,
        text: 'Request to Publish',
      },
    ];

    return {
      stepsCompleted,
      shippingAppCreationSteps,
    };
  },
});
</script>
