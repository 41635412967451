import Vue from 'vue';
import { defineComponent, reactive } from '@vue/composition-api';
import {
  ZidInput,
  ZidSelect,
  ZidSelectHeader,
  ZidSelectBody,
  ZidSelectOption,
  ZidCheckbox,
  ZidIcon,
  ZidButton,
  ZidTextarea,
  ZidLoader,
  ZidRadio,
  ZidModal,
  ZidTable,
  ZidTableRow,
  ZidTableRowGroup,
  ZidTableHeader,
  ZidTableCell,
} from '@zidsa/ui';
import EditIcon from '@/assets/icons/applications/EditIcon.vue';
import DeleteIcon from '@/assets/icons/applications/DeleteIcon.vue';
import Multiselect from 'vue-multiselect';
import BaseText from '@/components/text/BaseText.vue';
import BaseIcon from '@/components/base-icon/BaseIcon.vue';
import router from '@/router/index';
import { RoutingRouteEnum } from '@/router/routes.enum';
import SearchIcon from '@/assets/icons/applications/SearchIcon.vue';
import BreadCrumb from '@/domains/shipping/components/breadcrumb/BreadCrumb.vue';
import TabMultiLanguage from '@/components/tab-multi-language/TabMultiLanguage.vue';
import useShippingOptionDetails from '@/domains/shipping/helper/hooks/shipping-option-details/useShippingOptionDetails';
import { setShippingOptionDetailsToApi } from '@/domains/shipping/api/steps/shipping-option-details/shippingOptionDetails';
import { createNamespacedHelpers } from 'vuex-composition-helpers';
import ErrorModel from '@/api/models/ErrorModel';

const { useActions } = createNamespacedHelpers('shipping'); // module name

export default defineComponent({
  components: {
    ZidInput,
    ZidSelect,
    ZidSelectHeader,
    ZidSelectBody,
    ZidSelectOption,
    BaseText,
    BaseIcon,
    ZidCheckbox,
    ZidIcon,
    ZidButton,
    ZidTextarea,
    BreadCrumb,
    ZidLoader,
    ZidRadio,
    ZidTable,
    ZidTableRow,
    ZidTableRowGroup,
    ZidTableHeader,
    ZidTableCell,
    ZidModal,
    SearchIcon,
    EditIcon,
    DeleteIcon,
    Multiselect,
    TabMultiLanguage,
  },
  setup() {
    const {
      v$,
      customizationList,
      shippingAppId,
      shippingOptionsFormState,
      partnerCustomizationCountries,
      partnerCustomizationCities,
      currencies,
      weightUnits,
      selectedMechanism,
      showAddCustomizationMsg,
      showAddCustomizationForm,
      SelectedPriceEnum,
      PricingTypesEnum,
      ActivationAndPricingMechanismEnum,
      selectedCODPricingType,
      selectedFixedShippingPricingType,
      showMessageForCustomizationNameArEnField,
      loadingCustomizationList,
      loadingDeleteCustomization,
      showDeleteCustomizationModal,
      loadingChangePricingMechanism,
      loadCustomizationDataInForm,
      showChangePricingMechanismModal,
      isAddNewCustomizationBtnDisable,
      isSaveBtnDisable,
      isSaveCustomizationBtnDisable,
      isUrlInvalid,
      CustomizationOptionLabelsEnum,
      ShippingOptionDetailsInputPropertiesEnum,
      isLoadingCustomizationCountries,
      isLoadingCustomizationCities,
      toggleChangePricingMechanismModal,
      onChangePricingMechanismConfirmationClicked,
      onEditCustomizationClick,
      onDeleteCustomizationClick,
      onDeleteSingleCustomizationConfirmationClicked,
      toggleDeleteCustomizationModal,
      customNameForLocations,
      onSelectedCountryRemove,
      toggleAddCustomizationForm,
      onPricingMechanismChange,
      onCODPricingTypeRadioClick,
      onShippingPricingTypeRadioClick,
      toggleAddCustomizationMsg,
      onShippingFixedPricingRadioClick,
      onInputDataChange,
      onCheckBoxValueChange,
      onResetCustomizationForm,
      onSaveCustomization,
    } = useShippingOptionDetails();

    const disabledBreadCrumbList = reactive([
      RoutingRouteEnum.CreateShippingApplication_WebhookManagement,
      RoutingRouteEnum.CreateShippingApplication_Publish,
    ]);

    const { addStepCompleted, setInProgressFullApplication } = useActions([
      'addStepCompleted',
      'setInProgressFullApplication',
    ]);

    const onBackClicked = () => {
      router.push({ name: RoutingRouteEnum.CreateShippingApplication_AboutCompany }).catch(() => {
        //
      });
    };

    const onSubmit = async () => {
      const contractLink = shippingOptionsFormState.activationURL;
      if (shippingAppId) {
        const response = await setShippingOptionDetailsToApi(shippingAppId, contractLink);
        if (response instanceof ErrorModel || !response.payload) {
          Vue.$toast.error('Failed to Update Application Details');
          return;
        }
        const newFullApp = {
          payload: { ...response.payload },
        };
        setInProgressFullApplication(newFullApp);
        addStepCompleted(RoutingRouteEnum.CreateShippingApplication_WebhookManagement);
        router
          .push({
            name: RoutingRouteEnum.CreateShippingApplication_WebhookManagement,
          })
          .catch(() => {
            //
          });
        Vue.$toast.success('Application Details are Updated Successfully');
      } else {
        Vue.$toast.error('Failed to Load Application Details');
      }
    };

    return {
      disabledBreadCrumbList,
      v$,
      customizationList,
      shippingOptionsFormState,
      partnerCustomizationCountries,
      partnerCustomizationCities,
      currencies,
      weightUnits,
      selectedMechanism,
      showAddCustomizationMsg,
      showAddCustomizationForm,
      SelectedPriceEnum,
      PricingTypesEnum,
      ActivationAndPricingMechanismEnum,
      selectedCODPricingType,
      selectedFixedShippingPricingType,
      showMessageForCustomizationNameArEnField,
      loadingCustomizationList,
      loadingDeleteCustomization,
      showDeleteCustomizationModal,
      loadingChangePricingMechanism,
      loadCustomizationDataInForm,
      showChangePricingMechanismModal,
      isAddNewCustomizationBtnDisable,
      isSaveBtnDisable,
      isSaveCustomizationBtnDisable,
      isUrlInvalid,
      CustomizationOptionLabelsEnum,
      ShippingOptionDetailsInputPropertiesEnum,
      isLoadingCustomizationCountries,
      isLoadingCustomizationCities,
      toggleChangePricingMechanismModal,
      onChangePricingMechanismConfirmationClicked,
      onEditCustomizationClick,
      onDeleteCustomizationClick,
      onDeleteSingleCustomizationConfirmationClicked,
      toggleDeleteCustomizationModal,
      customNameForLocations,
      onSelectedCountryRemove,
      toggleAddCustomizationForm,
      onPricingMechanismChange,
      onCODPricingTypeRadioClick,
      onShippingPricingTypeRadioClick,
      toggleAddCustomizationMsg,
      onShippingFixedPricingRadioClick,
      onInputDataChange,
      onCheckBoxValueChange,
      onResetCustomizationForm,
      onSaveCustomization,
      onBackClicked,
      onSubmit,
    };
  },
});
