import Vue from 'vue';
import { defineComponent, ref, onBeforeMount } from '@vue/composition-api';
import BreadCrumb from '@/domains/shipping/components/breadcrumb/BreadCrumb.vue';
import BaseText from '@/components/text/BaseText.vue';
import BaseIcon from '@/components/base-icon/BaseIcon.vue';
import EnterIcon from '@/assets/icons/applications/EnterIcon.vue';
import { ZidButton, ZidModal, ZidIcon } from '@zidsa/ui';
import ExclamationIcon from '@/assets/icons/domains/partnership-details/ExclamationIcon.vue';
import router from '@/router/index';
import { RoutingRouteEnum } from '@/router/routes.enum';
import { sendAppForReview } from '@/domains/shipping/api/steps/publish-app/publishApp';
import { welcomePartner } from '@/api/top-level-apis/welcome-partner/welcomePartner';
import ErrorModel from '@/api/models/ErrorModel';
import { createNamespacedHelpers } from 'vuex-composition-helpers';

const { useGetters } = createNamespacedHelpers('shipping');

export default defineComponent({
  components: {
    BreadCrumb,
    BaseText,
    BaseIcon,
    ZidIcon,
    ZidButton,
    ZidModal,
    EnterIcon,
    ExclamationIcon,
  },
  setup() {
    const shippingAppId = ref();
    const hasCompletedPartnershipForm = ref(false);
    const mdUrl = ref('');
    const isAppInstalledOnDevStore = ref(false);
    const showPartnershipMessage = ref(false);
    const showReviewWarningMessage = ref(false);

    const { inProgressFullApp } = useGetters(['inProgressFullApp']);

    if (inProgressFullApp.value) {
      const {
        payload: { app, isAppInDevelopmentStore, mdLink },
      } = inProgressFullApp.value;
      shippingAppId.value = app.app_id;
      isAppInstalledOnDevStore.value = isAppInDevelopmentStore;
      mdUrl.value = mdLink;
    }

    const togglePartnershipMessage = () => (showPartnershipMessage.value = !showPartnershipMessage.value);
    const toggleReviewWarningMessage = () => (showReviewWarningMessage.value = !showReviewWarningMessage.value);

    onBeforeMount(async () => {
      const response = await welcomePartner();
      if (response instanceof ErrorModel || !response.payload) return;
      hasCompletedPartnershipForm.value = response.payload.partnership_contract;
      showPartnershipMessage.value = !response.payload.partnership_contract;
    });

    const onBackClicked = () => {
      router.push({ name: RoutingRouteEnum.CreateShippingApplication_WebhookManagement }).catch(() => {
        //
      });
    };

    const onSendForReviewClicked = async () => {
      const response = await sendAppForReview(shippingAppId.value);
      if (response instanceof ErrorModel || response.status !== 'success') {
        Vue.$toast.error('Failed to send request for review');
        return;
      }
      Vue.$toast.success('Application is sent for review successfully');
      router.push({ name: RoutingRouteEnum.Applications }).catch(() => {
        //
      });
    };

    const onSendBtnClicked = async () => {
      if (!shippingAppId.value) return;
      if (!hasCompletedPartnershipForm.value) {
        togglePartnershipMessage();
        return;
      }
      toggleReviewWarningMessage();
      return;
    };

    return {
      mdUrl,
      hasCompletedPartnershipForm,
      showPartnershipMessage,
      showReviewWarningMessage,
      shippingAppId,
      isAppInstalledOnDevStore,
      onBackClicked,
      onSendBtnClicked,
      onSendForReviewClicked,
      toggleReviewWarningMessage,
      togglePartnershipMessage,
    };
  },
});
