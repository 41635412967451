import BaseModel from '@/api/models/BaseModel';
import { WebhookResponse, AvailableWebhooksType } from '../types/create-app/createApp.enum';

class PartnerWebhooksResponseModel extends BaseModel {
  public payload: {
    webhooks: Record<string, WebhookResponse[]>;
    webhooksAvailable: AvailableWebhooksType;
  };

  constructor(arg: any) {
    super(arg);
    this.payload = arg.payload;
  }
}

export default PartnerWebhooksResponseModel;
