import BaseModel from '@/api/models/BaseModel';
import PartnerAvailableCitiesModel from './PartnerAvailableCitiesModel';

class PartnerAvailableCitiesResponseModel extends BaseModel {
  public cities: PartnerAvailableCitiesModel[];

  constructor(arg: any) {
    super(arg);
    this.cities = arg.cities;
  }
}

export default PartnerAvailableCitiesResponseModel;
