import {
  CompanyTypesLabelsEnum,
  CompanyTypesValuesEnum,
  SupportedCurrenciesLabelsEnum,
  SupportedCurrenciesValuesEnum,
  SupportedWeightLabelsEnum,
  SupportedWeightValuesEnum,
} from '../types/create-app/createApp.enum';

export const COMPANY_TYPES = [
  { label: CompanyTypesLabelsEnum.ShippingAndFulfillment, value: CompanyTypesValuesEnum.Fulfillment },
  { label: CompanyTypesLabelsEnum.ShippingOnly, value: CompanyTypesValuesEnum.Shipping },
];

export const SUPPORTED_CURRENCIES_FOR_CUSTOMIZATION = [
  { label: SupportedCurrenciesLabelsEnum.SAR, value: SupportedCurrenciesValuesEnum.SAR },
];

export const SUPPORTED_WEIGHTS_FOR_CUSTOMIZATION = [
  { label: SupportedWeightLabelsEnum.KG, value: SupportedWeightValuesEnum.KG },
];
